import { useEffect, useState } from "react";
import api from "../../services/api.js";
import DataTable from "react-data-table-component";
import "../manutencao/manutencao.css"

import { ref, uploadBytes, getDownloadURL, listAll, list, } from "firebase/storage";
import { storage } from "../../services/firebaseConfig.js";
import { dadosLogin } from "../../zustand/zuztand.js"

function Manutencao() {

    const [dados, setDados] = useState([]);
    const [desc_funcao, setDesc_funcao] = useState("");
    const [status_funao, setStatus_funcao] = useState("");
    const [id_funcao_col, setId_funcao_col] = useState(0);

    const [id_fornecedor, setId_fornecedor] = useState(0);
    const [id_franquia, setId_franquia] = useState(0);
    const [id_doc_fornecedor, setId_doc_fornecedor] = useState(0);
    const [url_doc_franq, setUrl_doc_franq] = useState("");
    const [descr_doc, setDescr_doc] = useState("");
    const [controle_doc_forn, setControle_doc_forn] = useState("");

    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [dadosUrl, setDadosUrl] = useState([]);
    const [url_imgagem_franquia, setUrl_imgagem_franquia] = useState("");
    const [pastaStorage, setPastaStorage] = useState("");
    const [atualiza, setAtualiza] = useState(false);

    const [id_event, setId_event] = useState("");
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [color, setColor] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [status_event, setStatus_event] = useState("");
    const [tipo_event, setTipo_event] = useState("");
    const [dadosEvents, setDadosEvents] = useState([]);

    const [horaInicio, setHoraInicio] = useState("");
    const [horaFim, setHoraFim] = useState("");
    const [dataFim, setDataFim] = useState("");


    //-------------------------------------------------------Tela Função Inicio -----------------------------------------------------//
    const columns = [
        {
            name: 'Cód.',
            selector: row => row.id_funcao_col,
            sortable: true,
            width: "20%",
        },
        {
            name: 'Função',
            selector: row => row.desc_funcao,
            sortable: true,
            width: "45%",
            compact: false
        },
        {
            cell: (row) => <>
                <button onClick={() => PegaDados(row.id_funcao_col, row.desc_funcao)} className="btn btn-success ms-2 " ><i className="bi bi-check2-square"></i></button>
            </>,
            name: 'Selecionar',
        }
    ];

    function ListarFuncao() {

        api.get("/funcao")
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao pesquisar dados");
            });
    }

    function PegaDados(id, funcao) {

        setDesc_funcao(funcao);
        setId_funcao_col(id);
        //localStorage.setItem('id_funcao', id);        
        document.getElementById("btsalvar").disabled = false;
        document.getElementById("btincluir").disabled = false;
        document.getElementById("btexcluir").disabled = false;
    }

    function ExcluirFuncao() {
        let id_func = id_funcao_col;

        let status_funcao = "I";
        const params = {
            status_funcao,
        };

        api.put("/funcao_edit_status/" + id_func, params)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro excluído com sucesso!");
                    ListarFuncao();

                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {

                alert("Erro ao editar dados");
            });

        Limpar();
    }

    function AlterarFuncao() {

        let id_func = id_funcao_col;

        const params = {
            desc_funcao,
        };

        api.put("/funcao_edit/" + id_func, params)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro editado com sucesso!");
                    ListarFuncao();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {

                alert("Erro ao editar dados");
            });

        Limpar();
    }

    function IncluirFuncao() {

        const params = {
            desc_funcao,
            status_funcao: "A",
        };

        if (desc_funcao === "") {
            alert("Campo função é obrigatório")
        } else {

            api.post("/inserir_funcao", params)

                .then((resp) => {

                    if (resp.status === 201) {
                        alert("Incluido com sucesso");
                        ListarFuncao();
                    } else {
                        alert("Erro ao inserir dados");
                    }
                })
                .catch((err) => {

                    alert("Erro ao inserir dados");
                });
        }
        Limpar();
    }

    function Limpar() {
        setDesc_funcao("");
        setId_funcao_col(null);

        document.getElementById("btsalvar").disabled = true;
        document.getElementById("btincluir").disabled = true;
        document.getElementById("btexcluir").disabled = true;
    }

    useEffect(() => {
        Limpar()
        ListarFuncao();
        document.getElementById("btsalvar").disabled = true;
        document.getElementById("btincluir").disabled = true;
        document.getElementById("btexcluir").disabled = true;

    }, []);
    //-------------------------------------------------------Tela Função Fim -----------------------------------------------------------//

    //-------------------------------------------------------Tela Firebase Inicio -------------------------------------------------------//

    function AtualiZaListaUrl() { //atualiza lista vindo do storage

        var dadosUrlDow = []
        listAll(imagesListRef2).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            //setImageUrls(dadosUrlDow)
            setDadosUrl(dadosUrlDow)
            //console.log(dadosUrl)
        });
    }

    useEffect(() => {

        var dadosUrlDow = []
        setImageUrls([])
        listAll(imagesListRef2).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    // console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            setDadosUrl(dadosUrlDow)

            setUrl_imgagem_franquia('')

        });


    }, [atualiza]);


    function PegaUrl() {
        var str = ""
        dadosUrl.map(function (item, indice) {
            str = JSON.stringify(item);
            if (str.match(/.pdf/)) {
                console.log('string encontrada');
            } else {
                console.log('não encontrada');
            }
        });
    }


    var dataAtua = new Date(); //para salvar as imagens com nomes diferentes com o getTime

    const imagesListRef2 = ref(storage, `franquias/arquivos/${id_franquia}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/

    const uploadFile = () => {
        setImageUrls([])
        if (imageUpload == null) return;

        const imageRef = ref(storage, `${imagesListRef2}/${imageUpload.name + dataAtua.getTime()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setImageUrls((prev) => [...prev, url]);
                AtualiZaListaUrl();
            });
        });
    };

    function PegarUrl_franquia(url) {
        setUrl_imgagem_franquia(url)
    }

    function ChangePastaStorage(e) {//para selecionar no combobox a pasta onde seará salvo ao arquivo no firebase
        setPastaStorage(e.target.value);
        setAtualiza(!atualiza)
    };


    function LimparCampos() {
        setId_franquia(0)
    }
    //-------------------------------------------------------Tela Firebase Fim -------------------------------------------------------//

    //-------------------------------------------------------Tela Calendario Inicio -----------------------------------------------------//
    const columnsCalendario = [
        {
            name: 'Cód.',
            selector: row => row.id_event,
            sortable: true,
            width: "9%",
        },
        {
            name: 'Evento',
            selector: row => row.title,
            sortable: true,
            width: "20%",
            compact: false
        },
        {
            name: 'Data evento',
            selector: row => row.date,
            sortable: true,
            width: "14%",
            compact: false
        },
        {
            name: 'Início',
            selector: row => row.start,
            sortable: true,
            width: "18%",
            compact: false
        },
        {
            name: 'Fim',
            selector: row => row.end,
            sortable: true,
            width: "18%",
            compact: false
        },
        {
            name: 'color',
            selector: row => row.color,
            sortable: true,
            width: "0%",
            compact: false
        },
        {
            name: 'tipo',
            selector: row => row.tipo_event,
            sortable: true,
            width: "15%",
            compact: false
        },
        {
            cell: (row) => <>
                <button onClick={() => PegaDadosEventos(row.id_event, row.title, row.date, row.start, row.end, row.color, row.tipo_event)} className="btn btn-success ms-2 " ><i className="bi bi-check2-square"></i></button>
            </>,
            name: 'Selecionar',
        }
    ];

    function ListarEventos() {

        api.get("/events_listar")
            .then((resp) => {
                setDadosEvents(resp.data);
            })
            .catch((err) => {
                setDadosEvents([]);
                alert("Erro ao pesquisar dados");
            });
    }


    function PegaDadosEventos(id, title, date, start, end, color, tipo) {

        setId_event(id);
        setTitle(title);
        setColor(color);
        setEnd(end);
        setStart(start);
        setDate(date);
        setTipo_event(tipo)
        document.getElementById("btsalvar-evt").disabled = false;
        //document.getElementById("btincluir-evt").disabled = false;
        document.getElementById("btexcluir-evt").disabled = false;
    }

    function ExcluirEvento() {

        let status_event = "I";
        const params = {
            status_event,
        };
        api.put("/evento_edit_status/" + id_event, params)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro excluído com sucesso!");
                    ListarEventos();
                } else {
                    alert("Erro ao excluir evento");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });

        LimparEventos();
    }


    function leftPad(value, totalWidth, paddingChar) { //para colocar zero a equeda
        var length = totalWidth - value.toString().length + 1;
        return Array(length).join(paddingChar || '0') + value;
    };

    function EditarEvento() {

        if (horaInicio === "") {
            localStorage.setItem('datatempoinicio', date);
        } else {
            localStorage.setItem('datatempoinicio', date + ' ' + horaInicio);
        }

        if (dataFim === "") {
            localStorage.setItem('datatempofim', date);
        } else {
            let novadata = dataFim.split("-")//para colocar zero a equeda
            let num = parseInt(novadata[2])
            let num2 = leftPad(num + 1, 2) //SOMANDO 1 PARA corrigir datas no calendário
            let novadata2 = (novadata[0] + '-' + novadata[1] + '-' + num2)
            localStorage.setItem('datatempofim', novadata2);
        }


        if (horaFim === "") {
            localStorage.setItem('datatempofim', date);
        } else {
            localStorage.setItem('datatempofim', dataFim + ' ' + horaFim);
        }

 
        const params = {
            title,
            date,
            start: localStorage.getItem('datatempoinicio'),
            end: localStorage.getItem('datatempofim'),
            tipo_event,
            color,
        };

        api.put("/event_edit/" + id_event, params)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro editado com sucesso!");
                    ListarEventos();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });

        LimparEventos();
    }    

    function IncluirEvento() {

        if (horaInicio === "") {
            localStorage.setItem('datatempoinicio', date);
        } else {
            localStorage.setItem('datatempoinicio', date + ' ' + horaInicio);
        }
        if (horaFim === "") {
            localStorage.setItem('datatempofim', date);
        } else {
            localStorage.setItem('datatempofim', dataFim + ' ' + horaFim);
        }

        if (dataFim === "") {
            localStorage.setItem('datatempofim', date);
        } else {
            let novadata = dataFim.split("-")//para colocar zero a equeda
            let num = parseInt(novadata[2])
            let num2 = leftPad(num + 1, 2) //SOMANDO 1 PARA corrigir datas no calendário
            let novadata2 = (novadata[0] + '-' + novadata[1] + '-' + num2)
            localStorage.setItem('datatempofim', novadata2);
        }

        const params = {
            title,
            date,
            start: localStorage.getItem('datatempoinicio'),
            end: localStorage.getItem('datatempofim'),
            tipo_event,
            color,
            status_event: "A"
        };

        if (title === "" | tipo_event === "Selecione" | date === "") {
            alert("Campos evento, data e tipo são obrigatórios")
        } else {

            api.post("/inserir_evento", params)

                .then((resp) => {

                    if (resp.status === 201) {
                        alert("Incluido com sucesso");
                        ListarEventos();
                    } else {
                        alert("Erro ao inserir dados");
                    }
                })
                .catch((err) => {

                    alert("Erro ao inserir dados");
                });
        }
        Limpar();
    }

    function LimparEventos() {
        setTitle("");
        setId_event(0);
        setColor("FF8C00");
        setEnd("");
        setStart("");
        setDate("");
        setTipo_event("Selecione");
        setHoraFim("");
        setHoraInicio("");


        document.getElementById("btsalvar-evt").disabled = true;
        //document.getElementById("btincluir-evt").disabled = true;
        document.getElementById("btexcluir-evt").disabled = true;
    }

    useEffect(() => {
        LimparEventos();
        ListarEventos();
        document.getElementById("btsalvar-evt").disabled = true;
        // document.getElementById("btincluir-evt").disabled = true;
        document.getElementById("btexcluir-evt").disabled = true;

    }, []);
    //-------------------------------------------------------Tela Calendario Fim -----------------------------------------------------------//



    return <>
        <div className="container-fluid">
            <div className="row flex-nowrapl">
                <div className="col py-3 me-3">
                    <div className="d-inline-flex">
                        <h5>Manutenção do Sistema</h5>
                    </div>
                    <div className="bg-white p-4 rounded-4 border mt-4">
                        <div className="d-flex justify-content-between mb-3">
                            <div className="d-inline-flex">
                                <button className="btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Cadastrar Função</button>
                                <button className="btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#ModalCalendario">Calendário</button>
                                <button onClick={LimparCampos} className="btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalFirebase">Firebase Arquivos</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        {/*----------------------------------------------- Modal Colaboradores Inicio -----------------------------------------*/}
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Cadastro de Função</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-6 bg-white  rounded-4 " >
                            <div className="container-fluid h-100 pt-4 sidebar p-4 ">
                                <div className="row  ">
                                    <div className="col-6   ">
                                        <label htmlFor="InputNome2" className=" ">Função</label>
                                        <input type="text" className="form-control " id="InputNome" onChange={(e) => setDesc_funcao(e.target.value)} value={desc_funcao} />
                                    </div>
                                    <div className="col-6 mb-2  ">
                                        <button id="btsalvar" onClick={AlterarFuncao} type="button" className="btn btn-sm btn btn-success mt-4 me-2 btnfunc">Salvar</button>
                                        <button id="btincluir" onClick={IncluirFuncao} type="button" className="btn btn-sm btn btn-primary mt-4 me-2 btnfunc">Incluir</button>
                                        <button id="btexcluir" onClick={ExcluirFuncao} type="button" className="btn btn-sm btn btn-danger mt-4 me-2 btnfunc">Excluir</button>
                                    </div>
                                </div>
                                <h4 className="d-inline">
                                    <h5 className="mt-3">Relação das funções cadastradas</h5>
                                </h4>
                                <div className="row  mt-3 tab-funcao_col">
                                    <div className="bg-white p-4 rounded-4 border">

                                        <DataTable className="tabela-funcao" columns={columns}
                                            data={dados}
                                            pagination={false}
                                            noDataComponent={<p className="no-data-found2"></p>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal Colaboradores Fim -----------------------------------------*/}


        {/*----------------------------------------------- Modal calendário Inicio -----------------------------------------*/}
        <div className="modal fade" id="ModalCalendario" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="ModalCalendario">Calendário</h1>
                        <button onClick={LimparEventos} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row bg-white  rounded-4 " >
                            <div className="container-fluid h-100 pt-4 sidebar p-4 ">
                                <div className="row  ">
                                    <div className="col-4  ">
                                        <label htmlFor="InputNome2" className=" ">Título/Evento</label>
                                        <input type="text" className="form-control " id="InputNome" onChange={(e) => setTitle(e.target.value)} value={title} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputNome2" className=" ">Data evento</label>
                                        <input type="date" className="form-control " id="InputNome" onChange={(e) => setDate(e.target.value)} value={date} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputNome2" className=" ">Fim evento</label>
                                        <input type="date" className="form-control " id="InputNome" onChange={(e) => setDataFim(e.target.value)} value={dataFim} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputNome2" className=" ">Hora inicio</label>
                                        <input type="time" className="form-control " id="InputNome" onChange={(e) => setHoraInicio(e.target.value)} value={horaInicio} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputNome2" className=" ">Hora fim</label>
                                        <input type="time" className="form-control " id="InputNome" onChange={(e) => setHoraFim(e.target.value)} value={horaFim} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputNome2" className=" ">Tipo</label>
                                        <select type="text" className="form-control " onChange={(e) => setTipo_event(e.target.value)} value={tipo_event} >
                                            <option value="">Selecione</option>
                                            <option value="eventos">eventos</option>
                                            <option value="comemoracoes">comemoracoes</option>
                                            <option value="ferias">ferias</option>
                                            <option value="inalguracoes">inalguracoes</option>
                                            <option value="convencao">convencao</option>
                                        </select>
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputNome2" className=" ">Cor</label>
                                        <select type="text" className="form-control " onChange={(e) => setColor(e.target.value)} value={color} >
                                            <option value="">Selecione</option>
                                            <option value="#0000FF">Azul</option>
                                            <option value="#7CFC00">Verde</option>
                                            <option value="#FF8C00">Laranja</option>
                                            <option value="#FF0000">Vermelho</option>
                                            <option value="#D3D3D3">Cinza</option>
                                        </select>
                                    </div>

                                    <div className="col-6 mb-2  ">
                                        <button id="btsalvar-evt" onClick={EditarEvento} type="button" className="btn btn-sm btn btn-success mt-4 me-5 btnfunc">Salvar</button>
                                        <button id="btincluir-evt" onClick={IncluirEvento} type="button" className="btn btn-sm btn btn-primary mt-4 me-5 btnfunc">Incluir</button>
                                        <button id="btexcluir-evt" onClick={ExcluirEvento} type="button" className="btn btn-sm btn btn-danger mt-4 me-2 btnfunc">Excluir</button>
                                    </div>
                                </div>
                                <h4 className="d-inline">
                                    <h5 className="mt-3">Relação dos eventos cadastrados</h5>
                                </h4>
                                <div className="col-11 mt-3 ">
                                    <div className="bg-white p-4 rounded-4 border">

                                        <DataTable className="tabela-calendario" columns={columnsCalendario}
                                            data={dadosEvents}
                                            pagination={false}
                                            noDataComponent={<p className="no-data-found2"></p>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button onClick={LimparEventos} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal calendário Fim -----------------------------------------*/}

        {/*----------------------------------------------- Modal Arquivos Firebase  Inicio -----------------------------------------*/}
        <div className="modal fade" id="exampleModalFirebase" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Arquivos Firebase</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <div className="col-12 bg-white  rounded-4 " >
                            <div className="container-fluid pt-2 ">
                                <div className="d-flex">
                                    <h5>Selecione a pasta onde será salvo o arquivo no firebase, verifique o id da franquia escolhida.</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="col-2 ">
                                        <small className="text-secondary">Pastas</small>
                                        <select type="text" className="form-control " onChange={ChangePastaStorage}>
                                            <option value="">Selecione</option>
                                            <option value="Projeto">Projeto</option>
                                            <option value="Inalguração">Inalguração</option>
                                            <option value="Eventos">Eventos</option>
                                            <option value="Visitas">Visitas</option>
                                            <option value="Pdf">Pdf</option>
                                        </select>
                                    </div>
                                    <div className="col-2 ms-3">
                                        <small className="text-secondary">ID</small>
                                        <input type="text" className="form-control cnpj " onChange={(e) => setId_franquia(e.target.value)} value={id_franquia} />

                                    </div>

                                </div>
                                <div className="col-2">
                                    <small className="text-secondary">ID Selecionado</small>
                                    <input type="text" className="form-control cnpj " value={id_franquia} />
                                </div>

                                <div className="col-11 mt-3">
                                    <input
                                        type="file"
                                        onChange={(event) => {
                                            setImageUpload(event.target.files[0]);
                                        }}
                                    />
                                    <div className="d-flex mt-3">
                                        <button className="btn btn-primary mb-3" onClick={uploadFile}> Incluir Imagem</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal Arquivos Firebase Fim -----------------------------------------*/}

    </>
}
export default Manutencao;