import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../franquias-senhas-portal/franquias-senhas-portal.css"
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";

import { Link } from "react-router-dom";

function FranquiasSenhasPortal() {

    const [dados, setDados] = useState([]);
    const [site, setSite] = useState("");
    const [id_franquia, setId_franquia] = useState(0);
    const [nome_franquia, setNome_franquia] = useState("");
    const [descricao, setDescricao] = useState("");
    const [usuario, setUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const [id_senhas, setId_senhas] = useState(0);

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_senhas).slice(-4),  //para colocar zero a esquerda
            sortable: true,
            width: "0%",
        },
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true,
            width: "23%",
            compact: true
        },   
        {
            name: 'Usuário',
            selector: row => row.usuario,
            sortable: true,
            width: "35%",
        },
        {
            name: 'Senha.',
            selector: row => row.senha,
            sortable: true,
            width: "30%",
            compact: true
        },
        {
            name: 'Selecionar',
            center: true,
            cell: (row) => <>
                <button onClick={() => EditarSenhasFranquia(row.id_senhas, row.descricao, row.usuario, row.senha, row.site)} className="btn btn-success ms-3"><i className="bi bi-check2"></i></button>
            </>,
            width: "9%",
        }
    ];

    function AltStatusSenhaFranquia() {

        const param2 = {
            status_senha: "I",
        }

        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/senhas_status_edit/" + id_senhas, param2)
                            .then((resp) => {
                                if (resp.status === 200) {
                                    PesquisarSenhasFranquias();
                                    limpar();
                                } else {
                                    alert("Erro ao editar dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao editar dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }


    function EditarDadosSenhasFranquia() {

        const params2 = {
            site,
            descricao,
            usuario,
            senha
        };


        api.put("/senhas_franquias_edit/" + id_senhas, params2)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro editado com sucesso!");
                    PesquisarSenhasFranquias();
                    limpar();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });
    }

    function IncluirDadosSenhasFranquia() {

        let status_senha = "A";
        const params = {
            site,
            descricao,
            id_franquia,
            usuario,
            senha,
            status_senha
        };
        api.post("/senhas_franquias_insert", params)
            .then((resp) => {

                if (resp.status === 201) {
                    alert("Incluído com sucesso");
                    PesquisarSenhasFranquias();
                    limpar();
                } else {
                    alert("Erro ao inserir dados franquia");
                }
            })
            .catch((err) => {
                //setDados([]);
                alert("Erro ao inserir dados ");
            });
    }

    function limpar() {
        setSite("");
        setDescricao("");
        setUsuario("");
        setSenha("");
        setId_senhas(0);
    }

    function EditarSenhasFranquia(id, descricao, usuario, senha, site) { //pega od dados par preencher os imputs

        localStorage.setItem('id_senhas', id);
        limpar();
        setId_senhas(id);
        setDescricao(descricao);
        setUsuario(usuario);
        setSenha(senha);
        setSite(site);
    }


    function PesquisarSenhasFranquias() {//

        let id_franquia = localStorage.getItem('id_franquia');
        api.get("/senhas_franquia_listar", { params: { id_franquia } })
            .then((resp) => {
                setDados(resp.data);
                //  console.log(resp)
            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    useEffect(() => {
        setId_franquia(localStorage.getItem('id_franquia'));
        setNome_franquia(localStorage.getItem('nome_franquia'));
        PesquisarSenhasFranquias();
    }, []);

    return <>
        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="d-inline justify-content-center  container-body-fran-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className=" col-6 ms-4 mb-2">
                        <input type="text" className="form-control" value={id_franquia + " - " + nome_franquia} disabled={true} />
                    </div>                  

                    <div className="col-1 ms-3">
                        <Link to="/franquias" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>

                </div>
                <div className="row d-flex">
                <div className="col-7 ms-4">                    
                    <small className="text-secondary">Site/Portal/Link</small>
                    <input type="text" className="form-control " onChange={(e) => setSite(e.target.value)} value={site} />
                </div>
                
                <div className="col-4">                    
                    <small className="text-secondary">Descrição</small>
                    <input type="text" className="form-control " onChange={(e) => setDescricao(e.target.value)} value={descricao} />
                </div>
                </div>            

                <div className="d-flex">
                    <div className="col-5 ms-4 ">
                        <small className="text-secondary">Usuário</small>
                        <input type="text" className="form-control " onChange={(e) => setUsuario(e.target.value)} value={usuario} />
                    </div>
                    <div className="col-5 ms-5 ">
                        <small className="text-secondary">Senha</small>
                        <input type="text" className="form-control " onChange={(e) => setSenha(e.target.value)} value={senha} />
                    </div>
                </div>

                <div className="col-10 mt-3 ms-5 text-end">
                    <button onClick={IncluirDadosSenhasFranquia} className="btn btn-success mb-2" disabled={!descricao || !usuario || !senha} type="button" id="button-addon2 ">Incluir</button>
                    <button onClick={EditarDadosSenhasFranquia} className="btn btn-primary mb-2 ms-4" disabled={!descricao || !usuario || !senha} type="button" id="button-addon2 ">Salvar</button>
                    <button onClick={AltStatusSenhaFranquia} className="btn btn-danger mb-2 ms-4" disabled={!descricao || !usuario || !senha} type="button" id="button-addon2 ">Excluir</button>
                </div>

                <div className="col-12 bg-white p-2 rounded-4 ">
                    <DataTable className="tabela-senhas-franquias" columns={columns}
                        data={dados}
                        pagination={false}
                        noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}

                    />

                </div>





            </div>
        </div >
    </>
}
export default FranquiasSenhasPortal;