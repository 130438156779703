import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAEbJXXOJN6QYmLgMQyCNOVl9Z2SYjPcs8",
    authDomain: "franqueadora-ce93d.firebaseapp.com",
    projectId: "franqueadora-ce93d",
    storageBucket: "franqueadora-ce93d.appspot.com",
    messagingSenderId: "981381994524",
    appId: "1:981381994524:web:9d1c545394545d002867d9",
    measurementId: "G-WQQ0DQC4K0"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
