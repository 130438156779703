import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt-br'
import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { dadosLogin } from "../../zustand/zuztand.js";

import "../aniversario-franqueados/aniversario-franqueados.css";

function AniversarioFranqueados() {
  const navigate = useNavigate();
  const { ifLogado, usuario, nivel_user } = dadosLogin()

  const local = moment().locale('pt-br').format('LL'); //converte a data par pt-br
  const local_novo = local.slice(5); // separa o texto inicial ex 12 se setembro de 2024 ficando somente setembro...
  const [dados, setDados] = useState([]);


  var Xmas95 = new Date();
  var month = Xmas95.getMonth();
  var mesAtual = month + 1;

  function PesquisarAniverFranquias() {

    let novo_array = [];

    api.get("/franqueados_busca")
      .then((resp) => {
        // console.log(resp.data)
        resp.data.map((item) => {
          let dtaAniver = parseInt(item.data_nasc_franqueado.substring(3, 5));

          if (dtaAniver === mesAtual) {
            novo_array.push({
              nome: item.nome_franqueado,
              img: item.img_franqueado,
              data: item.data_nasc_franqueado
            })
          }
        })
        // console.log(novo_array)
        setDados(novo_array);
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  useEffect(() => {
    PesquisarAniverFranquias();

  }, []);

  return <>
    <div className="d-flex container-fundo-franqueado">
      <div className="d-block container-body-franqueado">
        {
          ifLogado !== "notLogad" ? <>

            <div className="d-flex justify-content-between">
              <div className="col-1">
              </div>
              <h4 className="ms-3 mt-2">
                Franqueados Aniversariantes: {local_novo}
              </h4>
              <div className="col-1 ">
                <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 btn-sm" >Voltar </Link>
              </div>
            </div>
            <div className="coluna-aniversariantes-franqueado">
              {
                dados.map((item) => {
                  return <div key={item.id_franqueado} className="d-flex justify-content-center  ">
                    <div className="col-4 ">
                      <div className="card mt-2 ms-3 " >
                        {
                          item.img ?
                            <img src={item.img} className="card-img-top img-card-franqueado ms-3 mt-3" alt="..." /> : <img src="https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/colaboradores%2Ffoto_aniver%2Faniver_padrao.jpg?alt=media&token=e8c4419a-0d2b-4c0c-ac7c-0926bd8b848f" className="card-img-top img-card-franqueado ms-3 mt-3" alt="..." />
                        }

                        <div className="card-body ms-4">
                          <h5 className="card-title">{item.nome}</h5>
                          <p className=" d-flex ">{item.data}</p>
                        </div>
                      </div>
                      <div className="card mt-4 ms-4" >
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </> : navigate('/login')
        }
      </div>
    </div>
  </>
}
export default AniversarioFranqueados;