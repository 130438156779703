import { useEffect, useState } from "react";
import api from "../../services/api.js";
import btnClear from "../../assets/clear.png";
import "../autocomplete-franquia/autocomplete-franquia.css"

function AutocompleteFranquia(props) {

    const [texto, setTexto] = useState("");
    const [dadosFranquias, setDadosFranquias] = useState([]); //todas as franquias geral    

    function ListarFranquiasGeral(buscaFranquia) {//somente id e nome para montar combo

        if (buscaFranquia.length > 2) {
            api.get("/franquia_busca/", { params: { buscaFranquia } })
                .then((resp) => {
                    setDadosFranquias(resp.data);
                    //console.log(resp.data)
                })
                .catch((err) => {
                    setDadosFranquias([]);
                    alert("Erro ao carregar dados");
                });
        }
    }

    function Filtrar(e) {
        setTexto(e.target.value);
        ListarFranquiasGeral(e.target.value);
    }

    function SelecionarFranquia(id, razao_franquia, cidade_franquia) {
        setTexto(razao_franquia); //para preencher o input de busca
        setDadosFranquias([]); //fecha caixa relaçao franquias  
        props.onClickId(id); // para enviar o id para a tela franquias
        props.onClickNome(razao_franquia);
        props.onClickCidade(cidade_franquia);   
    }

    function Clear() {
        setTexto("");
        props.onClickId(0);
        props.onClickNome("");
        props.onClickUrl(""); //para limpar url do frame
        setDadosFranquias([]);
        props.onClickCidade("");
    }

    useEffect(() => {
        document.addEventListener('click', (e) => setDadosFranquias([])); // quando clicar fora da tela de resultado fecha a mesma zerando o array
    }, []);

    return <div className="autocomplete-franquia col-12">
        <input type="text" className="form-control" onChange={Filtrar}
            placeholder={props.placeholder} value={texto} />

        <div className="autocomplete-franquia-items">
            {
                dadosFranquias.map((item) => {
                    return <div key={item.id_franquia}
                        className="autocomplete-franquia-item"
                        onClick={(e) => SelecionarFranquia(item.id_franquia, item.razao_franquia, item.cidade_franquia)}
                    >
                        <b>{item.razao_franquia}</b><br />
                        <small className="text-secondary">Cidade: {item.cidade_franquia}</small><br />
                        <small className="text-secondary">Código: {item.id_franquia}</small><br />
                    </div>
                })
            }
        </div>
        {
            texto.length > 0 ?
                <button className="autocomplete-franquia-clear" onClick={Clear}>
                    <img src={btnClear} className="autocomplete-franquia-img" />
                </button>
                : null
        }
    </div>
}

export default AutocompleteFranquia;