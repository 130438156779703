import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../colaboradores-autorizacao-storage/colaboradores-autorizacao-storage.css"

function ColaboradoresAutorizacaoStorage() {

    const [dados, setDados] = useState([]);
    const [url_doc_col, setUrl_doc_col] = useState("");
    const [id_colaborador, setId_colaborador] = useState(0);
    const [nome_colaborador, setNome_colaborador] = useState("");

    function PesquisarImgContrato() {//pesquisa ao selecionar a fornecedor no combobox antes do autocomplete

        let id_colab_fran = localStorage.getItem('id_colaborador');

        api.get("/colaborador_doc_url", { params: { id_colab_fran } })
            .then((resp) => {
                let arrayAutorizacao = [];
                resp.data.map((item) => {
                    var tipo = item.tipo_doc;
                    if (tipo === "autorizacao") {
                        arrayAutorizacao.push(item);
                    }
                })        
                setDados(arrayAutorizacao);//somentoes od dados com tipo contrato
            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ChangeUrl(e) {
        setUrl_doc_col(e.target.value);
    }

    useEffect(() => {
        setId_colaborador(localStorage.getItem('id_colaborador'));
        setNome_colaborador(localStorage.getItem('nome_colaborador'));
        PesquisarImgContrato();
    }, []);

    return <>
        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="d-inline justify-content-center  container-body-fran-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className=" col-6 ms-4">
                        <input type="text" className="form-control" value={id_colaborador + " - " + nome_colaborador} />
                    </div>

                    <div className="col-2  mb-3">
                        <select className="form-control select-url " name="url" id="url" onChange={ChangeUrl}>
                            <option value="">Selecione a autorização</option>
                            {
                                dados.map((item) => {
                                    return <option key={item.id_doc_colaborador} value={item.url_doc_col}>{item.descr_doc_col}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="col-1 ms-3">
                        <Link to="/colaboradores" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>

                </div>

                <div className="d-flex justify-content-center  overflow-auto">
                    <div className="  d-block">
                        <div className=" col-11">
                            <div className="">
                                <iframe className=" col-11 frame-contratos-franquias ms-4 " src={url_doc_col}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}
export default ColaboradoresAutorizacaoStorage;