import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import FornecedoresCad from "../fornecedores-cad/fornecedores-cad.jsx";
import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../fornecedores/fornecedores.css";
import fornecedoresPDF from "../../report/report.fornecedores/report.fornecedores.jsx";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand.js"


function Fornecedores() {
    const navigate = useNavigate();
    const [buscaCnpj, setBuscaCnpj] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(20);
    const [dadosForn, setDadosForn] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);

    const [regiao_forn, setRegiao_forn] = useState("");
    const [clas_fornecedor, setClas_fornecedor] = useState("");
    const [buscaFornecedor, setBuscaFornecedor] = useState("");
    const [buscaProduto, setBuscaProduto] = useState("");
    const [grupo_forn, setGrupo_forn] = useState("");
    const { ifLogado, usuario, nivel_user } = dadosLogin();

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_fornecedor).slice(-4), //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Fornecedor',
            selector: row => row.nome_fornecedor,
            sortable: true,
            width: "25%",
            compact: true
        },
        {
            name: 'CNPJ',
            selector: row => row.cnpj_fornecedor,
            sortable: true,
            width: "15%",
        },
        {
            name: 'Telefone',
            selector: row => row.tel_fornecedor,
            width: "10%",
            compact: true
        },
        {
            name: 'Grupo',
            selector: row => row.grupo_forn,
            width: "14%"
        },
        {
            name: 'Taxa rebate %',
            selector: row => row.taxa_rebate,
            width: "13%"
        },
        {
            name: 'Menu',
            center: true,
            cell: (row) => <>
                <select onClick={() => Pegarid_fornecedor(row.id_fornecedor, row.nome_fornecedor)} className="form-control select-acoes" name="acao" id="acao" onChange={ChangeMenuAcoes} >
                    <option value="selecione">Selecione</option>
                    <option value="editar">Editar</option>
                    <option value="catalogos">Catálogos</option>
                    <option value="contratos">Contratos</option>                   
                    {
                        nivel_user === "admin" ?
                            <option className="text-danger fw-bold" value="Excluir">Excluir</option> : null
                    }
                </select>
            </>,
            width: "12%",
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function BuscarFornecedorId() {

        let id_fornecedor = parseInt(localStorage.getItem('id_fornecedor'));

        api.get("/fornecedores_id/" + id_fornecedor)
            .then((resp) => {
                var dadosForne = [];
                dadosForne.push({ //montando o array com o objeto recebido
                    'id_fornecedor': resp.data.id_fornecedor,
                    'nome_fornecedor': resp.data.nome_fornecedor,
                    'tel_fornecedor': resp.data.tel_fornecedor,
                    'cnpj_fornecedor': resp.data.cnpj_fornecedor,
                    'grupo_forn': resp.data.grupo_forn,
                    'taxa_rebate': resp.data.taxa_rebate
                })
                setDadosForn(dadosForne);
                setTotalRegistros(dadosForne.length);
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
                console.log(err);
            });
    }

    function AltStatusFornecedor(id) {
        let id_fornecedor = id;

        const param2 = {
            status_fornecedor: "I",
        }
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão da Fornecedor?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/fornecedores_edit_status/" + id_fornecedor, param2)
                            .then((resp) => {
                                if (resp.status === 200) {
                                    localStorage.setItem('id_fornecedor', '0');//para controle de listagem ao ser removido item
                                    ListarFornecedor(localStorage.getItem("id_usuario"), "", 1, 1000);
                                } else {
                                    alert("Erro ao editar dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao editar dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function NovoFornecedor() {

        const event = new CustomEvent("openFornecedor", {
            detail: {
                operacao: "new"
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFornecedor(id) {

        localStorage.setItem('id_openFornecedor', id);
        const event = new CustomEvent("openFornecedor", {
            detail: {
                operacao: "edit",
                id_fornecedor: id
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFornecedorSalvo() { //para abrir o ultimo visualizado
        var idf = localStorage.getItem('id_fornecedor');

        const event = new CustomEvent("openFornecedor", {
            detail: {
                operacao: "edit",
                id_fornecedor: idf
            }
        });
        window.dispatchEvent(event);
    }

    function Pegarid_fornecedor(id, nome) {
        localStorage.setItem('id_fornecedor', id);
        localStorage.setItem('nome_fornecedor', nome);
    }

    function ChangeMenuAcoes(e) {
        if (e.target.value === "editar") {
            EditarFornecedor(parseInt(localStorage.getItem('id_fornecedor')));
        } else if (e.target.value === "catalogos") {
            navigate("/fornecedores-catalogos-storage");
        } else if (e.target.value === "contratos") {
            navigate("/fornecedores-contratos-storage");
        } else if (e.target.value === "rebate") {
            navigate("/fornecedores-rebate");
        } else if (e.target.value === "Excluir") {
            AltStatusFornecedor(parseInt(localStorage.getItem('id_fornecedor')))
        }
    }

    function ListarFornecedor(id_usuario, regiao_forn, pagina) {
        let qtd_reg_pagina = 1000;
        api.get("/fornecedores_listar", { params: { id_usuario, regiao_forn, pagina, qtd_reg_pagina } })
            .then((resp) => {
                //setTotalRegistros(resp.data.total_registros);
                setDadosForn(resp.data.dados);
                setTotalRegistros(resp.data.dados.length);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFornecedorGrupo(grupo_forn) {//lista os fornecedores pelo grupo A, B ou C.

        api.get("/fornecedores_lista_grupo", { params: { grupo_forn } })
            .then((resp) => {
                //console.log(resp.data.length)
                setDadosForn(resp.data);
                setTotalRegistros(resp.data.length);
            })
            .catch((err) => {
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFornecedorTipo(id_usuario, clas_fornecedor, pagina) {
        let qtd_reg_pagina = 1000;
        api.get("/fornecedores_listar_tipo", { params: { id_usuario, clas_fornecedor, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.length);
                setDadosForn(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorNome() {
        api.get("/fornecedores_busca/", { params: { buscaFornecedor } })
            .then((resp) => {
                setDadosForn(resp.data);
                setTotalRegistros(resp.data.length);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorProduto() {
        api.get("/fornecedores_produtos/", { params: { buscaProduto } })
            .then((resp) => {
                setDadosForn(resp.data);
                setTotalRegistros(resp.data.length);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorCnpj() {
        api.get("/fornecedores_busca_cnpj/", { params: { buscaCnpj } })
            .then((resp) => {
                setDadosForn(resp.data);
                setTotalRegistros(resp.data.length);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFornecedorTodos() {
        ListarFornecedor(localStorage.getItem("id_usuario"), "", 1, 100);// btn mostrar todos
    }

    function ChangeRegiao(e) {
        setClas_fornecedor("Selecione");
        setRegiao_forn(e.target.value);
        ListarFornecedor(localStorage.getItem("id_usuario"), e.target.value, 1, 1000);
    }

    function ChangeGrupo(e) {
        setGrupo_forn("");
        setGrupo_forn(e.target.value);
        ListarFornecedorGrupo(e.target.value);
    }

    function ChangeClasFornecedor(e) {
        setClas_fornecedor(e.target.value);
        ListarFornecedorTipo(localStorage.getItem("id_usuario"), e.target.value, 1, 1000);
    }

    function ChangePerRows(newQtd, page) {
        ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, page, 1000);
    }

    function AtualizaDados() {
        //ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, 1, qtd_reg_pagina);
        BuscarFornecedorId();
    }

    useEffect(() => {
        if (buscaFornecedor === "") {
            //ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, 1, qtd_reg_pagina);
        } else {
            PesquisarFornecedorNome();
        }
    }, [buscaFornecedor]);

    useEffect(() => {
        if (buscaProduto === "") {
        } else {
            PesquisarFornecedorProduto();
        }
    }, [buscaProduto]);

    useEffect(() => {
        if (buscaCnpj === "") {
        } else {
            PesquisarFornecedorCnpj();
        }
    }, [buscaCnpj]);

    useEffect(() => {
        localStorage.setItem("id_usuario", 1);

        let id_temp = localStorage.getItem('id_fornecedor');
        if (id_temp === "0") {
            ListarFornecedorTodos();
        } else {
            function esperaAtualizar() {
                BuscarFornecedorId(); // pesquisa o fornecedor pelo id armazenado no storage
            }
            setTimeout(esperaAtualizar, 500); // Executará esperaAtualizar após meio segundo  
        }

    }, []);

    return <>
        {
            ifLogado != "notLogad" ? <>
                <FornecedoresCad onClose={AtualizaDados} />
                <div className="container-fluid margem-top-fornec ms-4">
                    <Navbar tela="fornecedores" />
                    <div className="row flex-nowrapl me-4">
                        <div className="col py-3 me-3">
                            <div className="input-group justify-content-end">
                                <input onChange={(e) => setBuscaFornecedor(e.target.value)} type="text" className="form-control buscainput-fran" id="pesq" placeholder="Pesquisar Fornecedor" value={buscaFornecedor} />
                                <button onClick={ListarFornecedorTodos} className="btn btn-primary mb-2" type="button" >Mostrar Todos</button>
                                <button onClick={EditarFornecedorSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" id="button-addon2 "></button>
                            </div>
                            <div className="bg-white p-4 rounded-4 border ">
                                <div className="d-flex justify-content-between  mb-3  titulo-tabela">
                                    <div className="d-flex">
                                        <small className="text-secundary mt-2 ">Região</small>
                                        <div className="form-control div_regiao_forn ms-2">
                                            <select name="reg" id="reg" onChange={ChangeRegiao}>
                                                <option value="">Todas</option>
                                                <option value="Norte">Norte</option>
                                                <option value="Sul">Sul</option>
                                                <option value="Sudeste">Sudeste</option>
                                                <option value="Nordeste">Nordeste</option>
                                                <option value="Centro-Oeste">Centro-Oeste</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <small className="text-secundary  mt-2 ">Grupo</small>
                                        <div className="form-control div_grupo_forn ms-2">
                                            <select name="reg" id="reg" onChange={ChangeGrupo}>
                                                <option value="">Sel.</option>
                                                <option value="A">Grupo A</option>
                                                <option value="B">Grupo B</option>
                                                <option value="C">Grupo C</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="d-flex ">
                                        <small className="text-secundary mt-2 me-1">Produtos</small>
                                        <input onChange={(e) => setBuscaProduto(e.target.value)} type="text" className="form-control buscainput-fran ms-2" id="pesq" placeholder="Ex: mobiliário" value={buscaProduto} />
                                    </div>

                                    <div className="d-flex" >
                                        <small className="text-secundary mt-2 me-1">CNPJ</small>
                                        <input type="text" className="form-control div_cnpj_forne ms-2" onChange={(e) => setBuscaCnpj(e.target.value)} placeholder="99.999.999/9999-99" />
                                    </div>
                                    <div className="align-items-end">
                                        <button onClick={(e) => fornecedoresPDF(dadosForn)} className="btn btn-danger ms-2"><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                                        {
                                            nivel_user === "admin" ?
                                                <button onClick={NovoFornecedor} className="btn btn-primary btn-fran-novo  ms-4 me-2">Novo</button> : null
                                        }
                                    </div>
                                </div>

                                <DataTable className="tabela-fornecedores2" columns={columns}
                                    data={dadosForn}
                                    //pagination={true}
                                    // paginationComponentOptions={paginationOptions}
                                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                                //paginationServer={true}
                                // paginationTotalRows={total_registros}
                                //onChangeRowsPerPage={ChangePerRows}
                                //onChangePage={ChangePage}
                                />
                                <div className="d-flex justify-content-end mt-2 me-4">
                                    <small >Total de registros:
                                        {total_registros}
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default Fornecedores;