import { Dock } from "react-dock";
import { useEffect, useState } from "react";
import "./fornecedores-cad.css";
import api from "../../services/api.js";
import { Link } from "react-router-dom";
import { dadosLogin } from "../../zustand/zuztand.js"
import Logo from "../../assets/cl_logo.png"


function FornecedoresCad(props) {

    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [base64Image, setBase64Image] = useState("");
    const [nome_fornecedor, setNome_fornecedor] = useState("");
    const [razao_fornecedor, setRazao_fornecedor] = useState("");
    const [tel_fornecedor, setTel_fornecedor] = useState("");
    const [regiao_forn, setRegiao_forn] = useState("");
    const [end_fornecedor, setEnd_fornecedor] = useState("");
    const [cnpj_fornecedor, setCnpj_fornecedor] = useState("");
    const [id_fornecedor, setId_fornecedor] = useState(0);
    const [cep_fornecedor, setCep_fornecedor] = useState("");
    const [bairro_fornecedor, setBairro_fornecedor] = useState("");
    const [uf_fornecedor, setUf_fornecedor] = useState("");
    const [cidade_fornecedor, setCidade_fornecedor] = useState("");
    const [comp_fornecedor, setComp_fornecedor] = useState("");
    const [num_fornecedor, setNum_fornecedor] = useState("");
    const [obs_fornecedor, setObs_fornecedor] = useState("");
    const [data_encerramento, setData_encerramento] = useState("");
    const [motivo_fornecedor, setMotivo_fornecedor] = useState("");
    const [clas_fornecedor, setClas_fornecedor] = useState("");
    const [contato_fornecedor, setContato_fornecedor] = useState("");
    const [ie_fornecedor, setIe_fornecedor] = useState("");
    const [im_fornecedor, setIm_fornecedor] = useState("");
    const [data_fornecedor, setData_fornecedor] = useState("");
    const [obs_contrato, setObs_contrato] = useState("");
    const [img_condicao, setImg_condicao] = useState("");
    const [grupo_forn, setGrupo_forn] = useState("");
    const [taxa_rebate, setTaxa_rebate] = useState("");
    const { usuario, nivel_user } = dadosLogin();

    function getCep() {
        const cepForn = cep_fornecedor.replace(/\D/g, '')
        if (cep_fornecedor.length !== 8) {
            alert("CEP inválido.");

        } else {
            fetch(`https://viacep.com.br/ws/${cep_fornecedor}/json/`).then(res => res.json()).then(data => {
                //  console.log(data);

                if (data.erro) {
                    alert("CEP não encontrado.")
                } else {
                    setEnd_fornecedor(data.logradouro);
                    // localStorage.setItem('Rua', data.logradouro);
                    setBairro_fornecedor(data.bairro);
                    // localStorage.setItem('Bairro', data.bairro);
                    setCidade_fornecedor(data.localidade);
                    //localStorage.setItem('Local', data.localidade);
                    // setCep(data.cep);
                    setUf_fornecedor(data.uf);
                }
            });
        }
    }

    function Limpar() {
        setNome_fornecedor("");
        setRazao_fornecedor("");
        setEnd_fornecedor("");
        setCnpj_fornecedor("");
        setTel_fornecedor("");
        setCep_fornecedor("");
        setBairro_fornecedor("");
        setUf_fornecedor("");
        setCidade_fornecedor("");
        setComp_fornecedor("");
        setNum_fornecedor("");
        setObs_fornecedor("");
        setData_encerramento("");
        setMotivo_fornecedor("");
        setIe_fornecedor("");
        setIm_fornecedor("");
        setObs_contrato("");
        setData_fornecedor("");
        setClas_fornecedor("");
        setContato_fornecedor("");
        setImg_condicao("");
        setGrupo_forn("C");
        setTaxa_rebate("");
    }

    /*
    function ChangeDataInalgFranquia(e) {
        let ano = e.target.value.substring(0, 4);
        let mes = e.target.value.substring(5, 7);
        let dia = e.target.value.substring(8, 11);
        setData_inalg(dia + '/' + mes + '/' + ano);
    }
*/

    function SalvarDadosFornecedor() {

        if (uf_fornecedor === "PR" || uf_fornecedor === "SC" || uf_fornecedor === "RS") {
            localStorage.setItem('regiao_forn', 'Sul');

        } else if (uf_fornecedor === "SP" || uf_fornecedor === "RJ" || uf_fornecedor === "MG" || uf_fornecedor === "ES") {
            localStorage.setItem('regiao_forn', 'Sudeste');

        } else if (uf_fornecedor === "MS" || uf_fornecedor === "MT" || uf_fornecedor === "GO" || uf_fornecedor === "DF") {
            localStorage.setItem('regiao_forn', 'Centro-Oeste');

        } else if (uf_fornecedor === "MA" || uf_fornecedor === "PI" || uf_fornecedor === "CE" || uf_fornecedor === "RN" || uf_fornecedor === "PB" || uf_fornecedor === "PE" || uf_fornecedor === "AL" || uf_fornecedor === "SE" || uf_fornecedor === "BA") {
            localStorage.setItem('regiao_forn', 'Nordeste');

        } else if (uf_fornecedor === "RO" || uf_fornecedor === "AC" || uf_fornecedor === "AM" || uf_fornecedor === "RR" || uf_fornecedor === "AP" || uf_fornecedor === "PA" || uf_fornecedor === "TO") {
            localStorage.setItem('regiao_forn', 'Norte');
        }

        if (nome_fornecedor === "" || cnpj_fornecedor === "" || uf_fornecedor === "Selecione" || uf_fornecedor === "" || clas_fornecedor === "" || clas_fornecedor === "Selecione") {
            alert("Campo nome,  CNPJ , UF e Linha de produtos são obrigatórios")
        } else {

            let status_fornecedor = "A";
            let id_usuario = 1;
            let regiaoforn = localStorage.getItem('regiao_forn')

            const params = {
                nome_fornecedor,
                razao_fornecedor,
                regiao_forn: regiaoforn,
                end_fornecedor,
                cnpj_fornecedor,
                tel_fornecedor,
                status_fornecedor,
                cep_fornecedor,
                num_fornecedor,
                cidade_fornecedor,
                bairro_fornecedor,
                comp_fornecedor,
                uf_fornecedor,
                obs_fornecedor,
                id_usuario,
                data_encerramento,
                motivo_fornecedor,
                clas_fornecedor,
                ie_fornecedor,
                im_fornecedor,
                obs_contrato,
                contato_fornecedor,
                data_fornecedor,
                grupo_forn,
                taxa_rebate
            };

            const params2 = {

                nome_fornecedor,
                razao_fornecedor,
                regiao_forn: regiaoforn,
                end_fornecedor,
                cnpj_fornecedor,
                tel_fornecedor,
                cep_fornecedor,
                num_fornecedor,
                cidade_fornecedor,
                bairro_fornecedor,
                comp_fornecedor,
                uf_fornecedor,
                obs_fornecedor,
                data_encerramento,
                motivo_fornecedor,
                clas_fornecedor,
                ie_fornecedor,
                im_fornecedor,
                obs_contrato,
                contato_fornecedor,
                data_fornecedor,
                grupo_forn,
                taxa_rebate
            };

            if (operacao === "new") {
                // NOVO REGISTRO........................

                api.post("/fornecedores_insert", params)
                    .then((resp) => {

                        if (resp.status === 201) {
                            alert("Incluído com sucesso")
                            setShow(false);
                            props.onClose(); //para atualizar a tabela 
                        } else {
                            alert("Erro ao inserir dados franquia");
                        }
                    })
                    .catch((err) => {
                        //setDados([]);
                        alert("Erro ao inserir dados ");
                    });

            } else {

                if (nome_fornecedor === "" || razao_fornecedor === "" || cnpj_fornecedor === "" || uf_fornecedor === "Selecione" || clas_fornecedor === "Selecione" || clas_fornecedor === "") {
                    alert("Campo nome,  e CNPJ são obrigatórios, uf inválido")
                } else {

                    // EDITAR REGISTRO EXISTENTE........................
                    let id = parseInt(localStorage.getItem("id_openFornecedor"))

                    api.put("/fornecedores_edit/" + id, params2)
                        .then((resp) => {
                            if (resp.status === 200) {
                                alert("Registro editado com sucesso!")
                                setShow(false);
                                props.onClose();
                            } else {
                                alert("Erro ao editar dados");
                            }
                        })
                        .catch((err) => {
                            alert("Erro ao editar dados");
                        });
                }
            }
        }

    }

    useEffect(() => {
        window.addEventListener('openFornecedor', (param) => {

            if (param.detail.operacao === "new") {
                Limpar();
                setUf_fornecedor("Selecione");

            } else {
                Limpar();
                setUf_fornecedor("Selecione");

                api.get("/fornecedores_id/" + param.detail.id_fornecedor)
                    .then((resp) => {

                        setId_fornecedor(resp.data.id_fornecedor);
                        setNome_fornecedor(resp.data.nome_fornecedor);
                        setRazao_fornecedor(resp.data.razao_fornecedor);
                        setEnd_fornecedor(resp.data.end_fornecedor);
                        setCnpj_fornecedor(resp.data.cnpj_fornecedor);
                        setTel_fornecedor(resp.data.tel_fornecedor);
                        setCep_fornecedor(resp.data.cep_fornecedor);
                        setBairro_fornecedor(resp.data.bairro_fornecedor);
                        setUf_fornecedor(resp.data.uf_fornecedor);
                        setCidade_fornecedor(resp.data.cidade_fornecedor);
                        setComp_fornecedor(resp.data.comp_fornecedor);
                        setNum_fornecedor(resp.data.num_fornecedor);
                        setObs_fornecedor(resp.data.obs_fornecedor);
                        setData_encerramento(resp.data.data_encerramento);
                        setMotivo_fornecedor(resp.data.motivo_fornecedor);
                        setContato_fornecedor(resp.data.contato_fornecedor);
                        setClas_fornecedor(resp.data.clas_fornecedor);
                        setIe_fornecedor(resp.data.ie_fornecedor);
                        setIm_fornecedor(resp.data.im_fornecedor);
                        setObs_contrato(resp.data.obs_contrato);
                        setData_fornecedor(resp.data.data_fornecedor);
                        setImg_condicao(resp.data.img_condicao);
                        setGrupo_forn(resp.data.grupo_forn);
                        setTaxa_rebate(resp.data.taxa_rebate);
                    })
                    .catch((err) => {
                        alert("Erro ao carregar dados");
                        console.log(err);
                    });
            }

            setShow(true);
            setOperacao(param.detail.operacao);
        });
    }, []);

    return <Dock position="right"
        isVisible={show}
        fluid={false}
        size={1200}
        onVisibleChange={(visible) => {
            setShow(visible);
        }}
    >
        <div className="container-fluid sidebar-nc p-3">
            <h4 className="d-inline "> {operacao === "edit" ? "Editar Fornecedor " : "Novo Fornecedor"} </h4>

            <div className="row ">

                <div className="col-4 mt-4 ">
                    <small className="text-secondary">CNPJ</small>
                    <input type="text" className="form-control " onChange={(e) => setCnpj_fornecedor(e.target.value)} value={cnpj_fornecedor} />
                </div>

                <div className="col-4 mt-4">
                    <small className="text-secondary">I E</small>
                    <input type="text" className="form-control " onChange={(e) => setIe_fornecedor(e.target.value)} value={ie_fornecedor} />
                </div>
                <div className="col-2 ms-5 mt-4">
                    <small className="text-secondary ">Tel.</small>
                    <input type="text" className="form-control tel-fran" onChange={(e) => setTel_fornecedor(e.target.value)} value={tel_fornecedor} />
                </div>

            </div>
            <div className="row mt-2">

                <div className="col-4 ">
                    <small className="text-secondary">I M.</small>
                    <input type="text" className="form-control " onChange={(e) => setIm_fornecedor(e.target.value)} value={im_fornecedor} />
                </div>

                <div className="col-4 ">
                    <small className="text-secondary">Linha Produtos</small>
                    <input type="text" className="form-control " onChange={(e) => setClas_fornecedor(e.target.value)} value={clas_fornecedor} />
                </div>
                <div className="col-2 ms-5">
                    <small className="text-secondary ">Grupo</small>
                    <select type="text" className="form-control " onChange={(e) => setGrupo_forn(e.target.value)} value={grupo_forn} >
                        <option value="">Selecione</option>
                        <option value="A">Grupo A</option>
                        <option value="B">Grupo B</option>
                        <option value="C">Grupo C</option>
                    </select>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-9">
                    <small className="text-secondary">Razão Social</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setRazao_fornecedor(e.target.value)} value={razao_fornecedor} />
                </div>
            </div>

            <div className="d-flex justify-content-between mt-2">
                <div className="col-9">
                    <small className="text-secondary">Nome Fantasia</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setNome_fornecedor(e.target.value)} value={nome_fornecedor} />
                </div>
                <div className="col-2">
                    <div className="col-2">
                        <small className="text-secondary">CEP:</small>
                        <div className="col-11 blocoIconesCep-cad ">
                            <input type="text" className="form-control input-cep-cr  " onChange={(e) => setCep_fornecedor(e.target.value)} value={cep_fornecedor} />
                            <button onClick={getCep}><i className=" bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex mt-2">
                <div className="col-9">
                    <small className="text-secondary">Rua/Av.</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setEnd_fornecedor(e.target.value)} value={end_fornecedor} />
                </div>

                <div className="col-1 ms-5">
                    <div className="col-12">
                        <small className="text-secondary">Número</small>
                        <input type="text" className="form-control " onChange={(e) => setNum_fornecedor(e.target.value)} value={num_fornecedor} />
                    </div>
                </div>

                <div className="col-1 ms-4">
                    <div className="col-12">
                        <small className="text-secondary">UF</small>
                        <select type="text" className="form-control " onChange={(e) => setUf_fornecedor(e.target.value)} value={uf_fornecedor} >
                            <option value="">Selecione</option>
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="col-9 mt-2">
                <small className="text-secondary">Bairro</small>
                <input type="text" className="form-control " onChange={(e) => setBairro_fornecedor(e.target.value)} value={bairro_fornecedor} />
            </div>

            <div className="row mt-2 ">
                <div className="col-6">
                    <small className="text-secondary">Cidade</small>
                    <input type="text" className="form-control " onChange={(e) => setCidade_fornecedor(e.target.value)} value={cidade_fornecedor} />
                </div>

                <div className="col-6">
                    <small className="text-secondary">Complemento</small>
                    <input type="text" className="form-control " onChange={(e) => setComp_fornecedor(e.target.value)} value={comp_fornecedor} />
                </div>
            </div>

            <div className="col-10 mt-2">
                <small className="text-secondary">Obs:</small>
                <input type="text" className="form-control nome-cr" onChange={(e) => setObs_fornecedor(e.target.value)} value={obs_fornecedor} />
            </div>

            <div className="row">
                <div className="col-8 mt-2">
                    <small className="text-secondary">Contato:</small>
                    <input type="text" className="form-control " onChange={(e) => setContato_fornecedor(e.target.value)} value={contato_fornecedor} />
                </div>

                <div className="col-2 mt-2">
                    <small className="text-secondary">Taxa Rebate %</small>
                    <input type="text" className="form-control " onChange={(e) => setTaxa_rebate(e.target.value)} value={taxa_rebate} />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-2 ">
                    <small className="text-secondary">Data Contrato</small>
                    <input type="text" className="form-control cpf" onChange={(e) => setData_fornecedor(e.target.value)} value={data_fornecedor} />
                </div>

                <div className="col-6 ">
                    <small className="text-secondary  ">Obs Contrato:</small>
                    <div className="col-1-neg blocoIconesFran-cad  ">
                        <input type="text" className="form-control  " onChange={(e) => setObs_contrato(e.target.value)} value={obs_contrato} />

                        <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#ModalColaboradores"><i className=" bi bi-search blocoIconesFran-cad"></i></button>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-2">
                    <small className="text-secondary">Data Encerramento</small>
                    <input type="text" className="form-control " onChange={(e) => setData_encerramento(e.target.value)} value={data_encerramento} />
                </div>

                <div className="col-8 ">
                    <small className="text-secondary">Motivo</small>
                    <input type="text" className="form-control " onChange={(e) => setMotivo_fornecedor(e.target.value)} value={motivo_fornecedor} />
                </div>
            </div>

            <div className="container-fluid sidebar p-3 ">
                <div className="row align-items-end footer ">
                    <div className="text-center">
                        <button className="btn btn-danger btn-fran-imprimir me-4"><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                        <button className="btn btn-outline-primary me-3 " onClick={() => setShow(false)}>Cancelar</button>

                        {
                            nivel_user === "admin" ?
                                <button className="btn btn-primary " onClick={SalvarDadosFornecedor} >Salvar Dados</button> : null
                        }

                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>

        {/*----------------------------------------------- Modal Colaboradores Inicio -----------------------------------------*/}
        <div className="modal fade" id="ModalColaboradores" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title fs-5" id="exampleModalLabel">Condições especiais do fornecedor</h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 bg-white rounded-4 " >
                            <div className="container-fluid h-100 pt-4  p-4 ">
                                <div className="row justify-content-center ">

                                    <div className="col-12 justify-content-center  ">
                                        {
                                            img_condicao ?
                                                <img src={img_condicao} alt="fornecedor" className="img-condicao" /> : <img src={Logo} alt="fornecedor" className="img-condicao" />
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal Colaboradores Fim -----------------------------------------*/}

    </Dock>
}

export default FornecedoresCad;