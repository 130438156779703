import { useState } from "react";
import { dadosLogin } from "../../zustand/zuztand";
import { Link } from "react-router-dom";
import "../calendario_ferias/calendario_ferias.css"
import Calendario from "../../components/calendario/calendario.jsx";

function CalendarioFerias(props) {
  const [userLogado, setUserLogado] = useState("");
  const { changeIfLogado, ifLogado } = dadosLogin();

  return <>

<div className="d-flex container-fundo-geral">
      <div className="d-flex container-body-geral align-items-center">
        <div className="col-10 border calendario-geral">
          <Calendario tipo="ferias" className="" />
        </div>
        <div>
          <Link to="/menu-calendario" className="btn btn-success p-2 mt-2 buton-ferias-voltar d-flex " >Voltar </Link>
          <button type="button" className="btn btn-primary mt-1 buton-ferias-listar d-flex" data-bs-toggle="modal" data-bs-target="#ModalLista">Listar</button>
        </div>
      </div>
    </div>

  </>
}
export default CalendarioFerias;