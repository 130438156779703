import { useEffect, useState } from "react";
import "../franquias-fotos-pesq-geral/franquias-fotos-pesq-geral.css"
import AutocompleteFranquia from "../../components/autocomplete-franquia/autocomplete-franquia.jsx";
import { Link, useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL, listAll, list, } from "firebase/storage";
import { storage } from "../../services/firebaseConfig.js";
import { dadosLogin } from "../../zustand/zuztand.js"

import api from "../../services/api.js";

function FranquiasFotoPesqGeral() {

    const [imageUrls, setImageUrls] = useState([]);
    const [dadosUrl, setDadosUrl] = useState([]);
    const [url_imgagem_franquia, setUrl_imgagem_franquia] = useState("");
    const [pastaStorage, setPastaStorage] = useState("");
    const [atualiza, setAtualiza] = useState(false);
    const [id_pesq, setId_pesq] = useState(0);
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin();

    const [codigo, setCodigo] = useState(0);
    const [razao_franquia, setRazao_franquia] = useState("");
    const [cidade_franquia, setCidade_franquia] = useState("");
    const [texto, setTexto] = useState("");
    const [escolhaRazao, setEscolhaRazao] = useState("");
    const [url_doc_fornecedor, setUrl_doc_fornecedor] = useState("");
    const [dadosFranquiasCidades, setDadosFranquiasCidades] = useState([]);
    const [buscaAbrang, setBuscaAbrang] = useState("");

    function PegarUrl_franquia(url) {
        setUrl_imgagem_franquia(url);
    }

    function ChangePastaStorage(e) {//para selecionar no combobox a pasta onde seará salvo ao arquivo no firebase
        setPastaStorage(e.target.value);
        setAtualiza(!atualiza)
    };

    function ChangeId_pesq(e) {
        setId_pesq(e.target.value);
        localStorage.setItem('id_pesq', e.target.value);
    }

    function usar() {
        setId_pesq(codigo);
        setAtualiza(!atualiza);
        setCidade_franquia("");
        //setRazao_franquia("");
        setTexto("");
        ListarFranquiasCidade();
        setEscolhaRazao(localStorage.getItem('escolhaRazao'));      
    }

    function ChangeId_franquia(e) {   
        let yy = e.target.value;
        let gg = yy.split('/');
        setCodigo(gg[0]);
        setCidade_franquia(gg[1]);
        setRazao_franquia(gg[2]);   
        localStorage.setItem('escolhaRazao', gg[2]);        
    }

    function Limpa_id() {
        setId_pesq(0);
        localStorage.setItem('escolhaRazao', '');
    }

    function ListarFranquiasCidade() {//somente id e nome para montar combo
        setDadosFranquiasCidades([])
        api.get("/franquia_busca_abrang/", { params: { buscaAbrang } })
            .then((resp) => {
                setDadosFranquiasCidades(resp.data);
            })
            .catch((err) => {
                setDadosFranquiasCidades([]);
                alert("Erro ao carregar dados");
            });
    }
    useEffect(() => {
        const imagesListRef3 = ref(storage, `franquias/arquivos/${id_pesq}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/
        var dadosUrlDow = []
        setImageUrls([])
        listAll(imagesListRef3).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            setDadosUrl(dadosUrlDow)
            setUrl_imgagem_franquia('')
        });
    }, [atualiza]);

    useEffect(() => {
        ListarFranquiasCidade();
    }, [buscaAbrang]);

    return <>
        {
            ifLogado !== "notLogad" ? <>
                <div className="d-flex justify-content-between mt-2">

                    <div className="d-flex col-10">
                        <div className="d-flex col-2 ms-3 d-none">
                            <small className="text-white me-2 mt-2 ">Id</small>
                            <input type="number" className="form-control input-number" onChange={ChangeId_pesq} value={id_pesq} />
                        </div>

                        <div className="d-flex ms-3">
                            <button className="bi bi-search text-white btn btn-success" data-bs-toggle="modal" data-bs-target="#ModalFranquias" onClick={Limpa_id}> Pesquisar
                            </button>
                        </div>

                        <div className="d-flex col-8 ms-3">                            
                            <input type="text" className="form-control" value={razao_franquia} disabled={true} />
                        </div>

                    </div>
                    <div className="col-2 ">
                        <div className="d-flex justify-content-end me-5">
                            <small className="text-white mt-2 me-2">Pastas</small>
                            <div className="col-8">
                                <select type="text" className="form-control" onChange={ChangePastaStorage}>
                                    <option value="">Selecione</option>
                                    <option value="Inalguração">Inalguração</option>
                                    <option value="Eventos">Eventos</option>
                                    <option value="Visitas">Visitas</option>
                                    <option value="Outras">Outras</option>
                                </select>
                            </div>

                            <div className="col-4 me-3">
                                <Link to="/menu-franquias" className="btn btn-success ms-4 " >Voltar</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex  container-body-fotos-geral ms-3 mt-3">
                    <div className="d-flex mt-3 ">
                        <div className="d-flex col-11 img-fluid" >
                            <iframe className="ms-4 frame-pesq-foto" src={url_imgagem_franquia} id="frame-fotos"></iframe>
                        </div>
                        <div className="col-1 coluna-img-foto">
                            {imageUrls.map((url, index) => {
                                return <>
                                    <div className="d-flex  ms-2  mt-3 " >
                                        <button onClick={() => PegarUrl_franquia(url)}><img className=" d-flex card-franquia me-2  " alt="Imagem" src={url} /></button>
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </div>

                {/*----------------------------------------------- Modal Franquias Inicio -----------------------------------------*/}
                <div className="modal fade" id="ModalFranquias" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Franquias</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 bg-white  rounded-4 " >
                                    <div className="container-fluid h-100 pt-4 sidebar p-4 ">

                                        <div className="d-flex mb-3">
                                            <div className="col-6 ">
                                                <input type="text" className="form-control" placeholder='Cidade....' value={buscaAbrang} onChange={(e) => setBuscaAbrang(e.target.value)} />
                                            </div>

                                            <div className="col-6 ms-2 ">
                                                <select className="form-control " name="url" id="url" onChange={ChangeId_franquia}>
                                                    <option value="">Selecione a cidade</option>
                                                    {
                                                        dadosFranquiasCidades.map((item) => {
                                                            return <option key={item.id_franquia} value={item.id_franquia + '/' + item.cidade_franquia + '/' + item.razao_franquia}>{item.cidade_franquia}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-8 ">
                                            < AutocompleteFranquia placeholder="Digite a razão social da franquia..."
                                                onClickId={setCodigo} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                                onClickNome={setRazao_franquia} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                                onClickTexto={setTexto} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                                onClickUrl={setUrl_doc_fornecedor}
                                                onClickCidade={setCidade_franquia}
                                                value={razao_franquia}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div className="">
                                            <h6>
                                                Nome: {razao_franquia}
                                            </h6>
                                            <small>
                                                Cidade: {cidade_franquia}
                                            </small>
                                        </div>


                                    </div>

                                    <div className="d-flex mt-3 justify-content-end">
                                        <button onClick={usar} type="button" className="btn btn-primary btn-sm butonn-usar" data-bs-dismiss="modal">Usar</button>
                                    </div>

                                    <hr />
                                    <div className="row  mt-3 ">
                                        <div className="bg-white ">
                                            <div className="d-flex justify-content-end ">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*----------------------------------------------- Modal Franquias Fim -----------------------------------------*/}

            </> : navigate('/login')
        }
    </>
}
export default FranquiasFotoPesqGeral;