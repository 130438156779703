import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand";
import { Link, useNavigate } from "react-router-dom";
import "../menuaniversariantes/menu-aniversariantes.css"

function MenuAniversariantes(props) {
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin()

    return <>
        {
            ifLogado !== "notLogad" ? <>
                <div className="d-flex container-fundo-menu-aniver">
                    <Navbar tela="aniversarios"/>

                    <div className="row container-body-menu-aniver">                     
                            <img className="banner-aniversariantes" src="https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/banners%2Faniversariantes.png?alt=media&token=c4e1993b-e9a6-4b3e-98d5-994540f2ce4b" alt="banner-aniversariantes" />
                     
                        <div className="conteudo fixed-bottom">
                            <div className="d-flex ms-5 justify-content-center">
                                
                            <div className="col-2 mb-2">
                                </div>
                                <div className="col-2 mb-2">
                                    <Link to="/aniversario-franqueados" className="btn-orange-aniver" aria-current="page">Franqueados</Link>
                                </div>
                                <div className="col-2 mb-2 ms-1">
                                    <Link to="/aniversario-franquias" className="btn-orange-aniver" aria-current="page">Franquias</Link>

                                </div>
                                <div className="col-2 mb-2 ms-1">
                                    <Link to="/aniversario-colaboradores" className="btn-orange-aniver" aria-current="page">Colaboradores</Link>
                                </div>
                                <div className="col-2 mb-2 ms-1">
                                <Link to="/aniversario-cidades" className="btn-orange-aniver" aria-current="page">Cidades</Link>
                                </div>
                                <div className="col-2 mb-2 ms-1">
                                <Link to="/aniversarios" className="btn-orange-aniver" aria-current="page">Imprimir</Link>
                                </div>
                                <div className="col-2 mb-2">
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default MenuAniversariantes;