import axios from "axios";

//const URL = "http://localhost:8082";

const URL = "https://servidorfranqueadora-production.up.railway.app";

const api = axios.create({
    baseURL: URL,
    auth: {
        username: "admin",
        password: "admin"    
    }
});

export default api;