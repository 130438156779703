import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../franqueados-documentos-storage/franqueados-documentos-storage.css"

function FranqueadosDocumentosStorage() {

    const [dados, setDados] = useState([]);
    const [url_doc_franqueado, setUrl_doc_franqueado] = useState("");
    const [id_franqueado, setId_franqueado] = useState(0);
    const [nome_franqueado, setNome_franqueado] = useState("");

    function PesquisarImgContrato() {//pesquisa ao selecionar a franqueado no combobox antes do autocomplete

        let id_franqueado = parseInt(localStorage.getItem('id_franqueado'));
     
        api.get("/franqueados_doc_url", { params: { id_franqueado } })
            .then((resp) => {                   
                setDados(resp.data);
                //console.log(resp.data)
            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ChangeUrl(e) {
        setUrl_doc_franqueado(e.target.value);    

    }

    useEffect(() => {
        setId_franqueado(localStorage.getItem('id_franqueado'));
        setNome_franqueado(localStorage.getItem('nome_franqueado'));  
        PesquisarImgContrato();
    }, []);

    return <>
        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="d-inline justify-content-center  container-body-fran-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className=" col-6 ms-4">
                        <input type="text" className="form-control" value={id_franqueado + " - " + nome_franqueado} />
                    </div>

                    <div className="col-2  mb-3">
                        <select className="form-control select-url " name="url" id="url" onChange={ChangeUrl}>
                            <option value="">Selecione o documento</option>
                            {
                                dados.map((item) => {
                                    return <option key={item.id_doc_franqueado} value={item.url_doc_franqueado}>{item.descr_doc}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="col-1 ms-3">
                        <Link to="/franqueados" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>

                </div>

                <div className="d-flex justify-content-center  overflow-auto">
                    <div className="  d-block">
                        <div className=" col-11">
                            <div className="">
                                <iframe className=" col-11 frame-contratos-franquias ms-4 " src={url_doc_franqueado}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}
export default FranqueadosDocumentosStorage;