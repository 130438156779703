import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand";
import { useNavigate } from "react-router-dom";
import "../menufornecedores/menu-fornecedores.css"
import { Link } from "react-router-dom";

function MenuFornecedores(props) {
    const [userLogado, setUserLogado] = useState("");
    const { changeIfLogado, ifLogado } = dadosLogin();

    return <>
        <div className="d-flex container-fundo-forn">
            <Navbar tela="fornecedores" />
            <div className="row container-body-forn">
                <img className="banner-fornecedores" src="https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/banners%2Ffornecedores.png?alt=media&token=76299d40-6f67-431e-b3b9-38c709741a4b" alt="banner-franquias" />
                <div className="fixed-bottom">
                    <div className="d-flex justify-content-center">
                        <div className="col-10 d-flex justify-content-between">
                            <div className="col-1">
                            </div>
                            <div className="col-2 mb-2">
                                <Link to="/fornecedores" className="btn-orange me-3 " aria-current="page">Dados Cadastrais</Link>
                            </div>
                            <div className="col-2 mb-2 ms-3">
                                <Link to="/fornecedores-contratos" className="btn-orange me-3 " aria-current="page">Contratos</Link>
                            </div>
                            <div className="col-2 mb-2 ms-3">
                                <Link to="/fornecedores-rebate" className="btn-orange me-3" aria-current="page">Rebate</Link>
                            </div>
                            <div className="col-2 mb-2 ms-3">
                                <Link to="/fornecedores-catalogos" className="btn-orange me-3" aria-current="page">Catálogos</Link>
                            </div>
                       
                            <div className="col-1"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
}
export default MenuFornecedores;