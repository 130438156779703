import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../franquias-projetos-storage/franquias-projetos-storage.css"

import { dadosLogin } from "../../zustand/zuztand.js"
import { Link, useNavigate } from "react-router-dom";

function FranquiasProjetosStorage() {


    const [url_doc_franquia, setUrl_doc_franquia] = useState("");
    const [nome_franquia, setNome_franquia] = useState("");


    const navigate = useNavigate();
    const { ifLogado, usuario, nivel_user } = dadosLogin()
  
    const [dados, setDados] = useState([]);
    const [url_proj_franquia, setUrl_proj_franquia] = useState("");
    const [id_franquia, setId_franquia] = useState(localStorage.getItem('id_franquia'));
  
    function PesquisarUrlProjLocalStorage() {  //pesquisa conforme o id da franquia selecionado no localstorage
   
      api.get("/franquia_proj", { params: { id_franquia } })
        .then((resp) => {
          setDados(resp.data);
          //console.log(resp.data)
        })
        .catch((err) => {
          setDados([]);
          alert("Erro ao carregar dados");
        });
    }

    function ChangeUrl(e) {
        setUrl_proj_franquia(e.target.value);
      }

    useEffect(() => {
        setId_franquia(localStorage.getItem('id_franquia'));
        setNome_franquia(localStorage.getItem('nome_franquia'));
        PesquisarUrlProjLocalStorage();

    }, []);   

    return <>
        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="d-inline justify-content-center  container-body-fran-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className=" col-6 ms-4">
                        <input type="text" className="form-control" value={id_franquia + " - " + nome_franquia} />
                    </div>

                    <div className="col-2  mb-3">
                        <select className="form-control select-url " name="url" id="url" onChange={ChangeUrl}>
                            <option value="">Selecione o projeto</option>
                            {
                                dados.map((item) => {
                                    return <option key={item.id_proj_franquias} value={item.url_proj_franquia}>{item.id_franquia + ' - ' + item.descr_proj}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="col-1 ms-3">
                        <Link to="/franquias" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>

                </div>
                <div className="d-flex justify-content-center  overflow-auto">
                    <div className="  d-block">
                        <div className=" col-11">
                            <div className="">
                                <iframe className=" col-11 frame-contratos-franquias ms-4 " src={url_proj_franquia}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}
export default FranquiasProjetosStorage;