import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/login/login.jsx";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Manutencao from "./pages/manutencao/manutencao.jsx";
import Franqueados from "./pages/franqueados/franqueados.jsx";
import Franquias from "./pages/franquias/franquias.jsx";
import Fornecedores from "./pages/fornecedores/fornecedores.jsx";
import Aniversarios from "./pages/aniversarios/aniversarios.jsx";
import Colaboradores from "./pages/colaboradores/colaboradores.jsx";
import MenuFranquias from "./pages/menufranquias/menu-franquias.jsx";
import MenuFornecedores from "./pages/menufornecedores/menu-fornecedores.jsx";
import MenuAniversariantes from "./pages/menuaniversariantes/menu-aniversariantes.jsx";
import MenuColaboradores from "./pages/menucolaboradores/menu-colaboradores.jsx";
import MenuCalendario from "./pages/menucalendario/menu-calendario.jsx";
import FranquiasContratos from "./pages/franquias-contratos/franquias-contratos.jsx";
import FornecedoresContratos from "./pages/fornecedores-contratos/fornecedores-contratos.jsx";
import FornecedoresCatalogos from "./pages/fornecedores-catalogos/fornecedores-catalogos.jsx";
import ColaboradoresContratos from "./pages/colaboradores-contratos/colaboradores-contratos.jsx";
import AniversarioColaboradores from "./pages/aniversario-colaboradores/aniversario-colaboradores .jsx";
import AniversarioCidades from "./pages/aniversario-cidades/aniversario-cidades.jsx";
import AniversarioFranquias from "./pages/aniversario-franquias/aniversario-franquias.jsx";
import AniversarioFranqueados from "./pages/aniversario-franqueados/aniversario-franqueados.jsx";
import ColaboradoresAutorizacoes from "./pages/colaboradores-autorizacoes/colaboradores-autorizacoes.jsx";
import CalendarioGeral from "./pages/calendario_geral/calendario_geral.jsx";
import CalendarioAniver from "./pages/calendario_aniver/calendario_aniver.jsx";
import CalendarioFerias from "./pages/calendario_ferias/calendario_ferias.jsx";
import CalendarioConvencao from "./pages/calendario_convencao/calendario_convencao.jsx";
import CalendarioInalguracoes from "./pages/calendario_inalguracoes/calendario_inalguracoes.jsx";
import FranquiasProjetosGeral from "./pages/franquias-projetos-geral/franquias-projetos-geral.jsx";
import FranquiasFotosPesqGeral from "./pages/franquias-fotos-pesq-geral/franquias-fotos-pesq-geral.jsx"
import FornecedoresRebate from "./pages/fornecedores-rebate/fornecedores-rebate.jsx";
import FranquiasContratosStorage from "./pages/franquias-contratos-Storage/franquias-contratos-Storage.jsx";
import FranquiasProjetosStorage from "./pages/franquias-projetos-storage/franquias-projetos-storage.jsx";
import FornecedoresContratosStorage from "./pages/fornecedores-contratos-storage/fornecedores-contratos-storage.jsx";
import FornecedoresCatalogosStorage from "./pages/fornecedores-catalogos-storage/fornecedores-catalogos-storage.jsx";
import FranquiasSenhasPortal from "./pages/franquias-senhas-portal/franquias-senhas-portal.jsx";
import FranquiasFotosView from "./pages/franquias-fotos-view/franquias-fotos-view.jsx";
import ColaboradoresContratosStorage from "./pages/colaboradores-contratos-storage/colaboradores-contratos-storage.jsx";
import ColaboradoresAutorizacaoStorage from "./pages/colaboradores-autorizacao-storage/colaboradores-autorizacao-storage.jsx";
import FranqueadosDocumentosStorage from "./pages/franqueados-documentos-storage/franqueados-documentos-storage.jsx";

import PaginaModelo from "./pages/pagina-modelo/pagina-modelo.jsx";
function Rotas(){
    return <BrowserRouter>
        <Routes>           
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />   
            <Route path="/manutencao" element={<Manutencao />} />  

            <Route path="/menu-franquias" element={<MenuFranquias/>} />
            <Route path="/menu-fornecedores" element={<MenuFornecedores/>} />
            <Route path="/menu-aniversariantes" element={<MenuAniversariantes/>} />
            <Route path="/menu-colaboradores" element={<MenuColaboradores/>} />
            <Route path="/menu-calendario" element={<MenuCalendario/>} />      

            <Route path="/franquias" element={<Franquias/>} />      
            <Route path="/franquias-contratos" element={<FranquiasContratos/>} />  
            <Route path="/franquias-projetos-geral" element={<FranquiasProjetosGeral/>} />       
            <Route path="/franquias-fotos-pesq-geral" element={<FranquiasFotosPesqGeral/>} />   
            <Route path="/franquias-contratos-storage" element={<FranquiasContratosStorage/>} />    
            <Route path="/franquias-projetos-storage" element={<FranquiasProjetosStorage/>} /> 
            <Route path="/franquias-senhas-portal" element={<FranquiasSenhasPortal/>} />   
            <Route path="/franquias-fotos-view" element={<FranquiasFotosView/>} /> 

            <Route path="/fornecedores" element={<Fornecedores/>} />      
            <Route path="/fornecedores-contratos" element={<FornecedoresContratos/>} /> 
            <Route path="/fornecedores-catalogos" element={<FornecedoresCatalogos/>} />    
            <Route path="/fornecedores-rebate" element={<FornecedoresRebate/>} />      
            <Route path="/fornecedores-contratos-storage" element={<FornecedoresContratosStorage/>} />
            <Route path="/fornecedores-catalogos-storage" element={<FornecedoresCatalogosStorage/>} />                 
    
            <Route path="/colaboradores" element={<Colaboradores/>} />
            <Route path="/colaboradores-contratos" element={<ColaboradoresContratos/>} />   
            <Route path="/colaboradores-autorizacoes" element={<ColaboradoresAutorizacoes/>} /> 
            <Route path="/colaboradores-contratos-storage" element={<ColaboradoresContratosStorage/>} />   
            <Route path="/colaboradores-autorizacao-storage" element={<ColaboradoresAutorizacaoStorage/>} />   
            
            <Route path="/franqueados" element={<Franqueados/>} />
            <Route path="/franqueados-documentos-storage" element={<FranqueadosDocumentosStorage/>} />    
                     
            <Route path="/aniversarios" element={<Aniversarios/>} />
            <Route path="/aniversario-colaboradores" element={<AniversarioColaboradores/>} />    
            <Route path="/aniversario-cidades" element={<AniversarioCidades/>} />  
            <Route path="/aniversario-franquias" element={<AniversarioFranquias/>} />  
            <Route path="/aniversario-franqueados" element={<AniversarioFranqueados/>} /> 
      
            <Route path="/calendario-geral" element={<CalendarioGeral/>} /> 
            <Route path="/calendario-aniver" element={<CalendarioAniver/>} /> 
            <Route path="/calendario-ferias" element={<CalendarioFerias/>} /> 
            <Route path="/calendario-convencao" element={<CalendarioConvencao/>} /> 
            <Route path="/calendario-inalguracoes" element={<CalendarioInalguracoes/>} />               
    
                             
            <Route path="/pagina-modelo" element={<PaginaModelo/>} />   

        </Routes>
    </BrowserRouter>
}

export default Rotas;