import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar.jsx";
import "../colaboradores/colaboradores.css"
import ColaboradoresCad from "../colaboradores-cad/colaboradores-cad.jsx";
import api from "../../services/api.js";

import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { dadosLogin } from "../../zustand/zuztand";

function Colaboradores() {

    const { changeIfLogado, ifLogado, usuario, nivel_user } = dadosLogin();
    const navigate = useNavigate();
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);
    const [busca, setBusca] = useState("");
    const [buscaCpf, setBuscaCpf] = useState("");

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_colab_fran).slice(-4), //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Colaborador',
            selector: row => row.nome_colaborador,
            sortable: true,
            width: "40%",
            compact: true
        },
        {
            name: 'Tel.',
            selector: row => row.cel_colaborador,
            width: "15%",
            right: true
        },
        {
            name: 'CPF',
            selector: row => row.cpf_colaborador,
            width: "15%",
            right: true
        },
        {
            cell: (row) => <>
                <select onClick={() => PegarId_colaborador(row.id_colab_fran, row.nome_colaborador)} className="form-control select-acoes-col" name="acao" id="acao" onChange={ChangeMenuAcoes} >
                    <option value="Selecione">Selecione</option>
                    <option value="Editar">Editar</option>
                    <option value="Contratos">Contratos</option>
                    <option value="Autorizacoes">Autorizações</option>
                    {
                        nivel_user === "admin" ?
                            <option className="text-danger fw-bold" value="Excluir">Excluir</option> : null
                    }
                </select>
            </>,
            width: "20%",
            right: true
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function ChangeMenuAcoes(e) {
        if (e.target.value === "Editar") {
            EditarColaborador(localStorage.getItem('id_colaborador'));
        } else if (e.target.value === "Contratos") {
            navigate("/colaboradores-contratos-storage");
        } else if (e.target.value === "Autorizacoes") {
            navigate("/colaboradores-autorizacao-storage");
        } else if (e.target.value === "Excluir") {
            AltStatusColaborador(parseInt(localStorage.getItem('id_colaborador')));
        }
    }

    function PegarId_colaborador(id, nome_colaborador) {
        localStorage.setItem('id_colaborador', id);
       localStorage.setItem('nome_colaborador', nome_colaborador);
       
    }

    function NovoColaborador() {
        const event = new CustomEvent("openColaboradores", {
            detail: {
                operacao: "new"
            }
        });
        window.dispatchEvent(event);
    }

    function EditarColaborador(id) {
        localStorage.setItem('id_colaborador', id);

        const event = new CustomEvent("openColaboradores", {
            detail: {
                operacao: "edit",
                id_colab_fran: id
            }
        });
        window.dispatchEvent(event);
    }

    function EditarColaboradorSalvo() { //para abrir o ultimo visualizado

        var idf = localStorage.getItem('id_colaborador');
        const event = new CustomEvent("openColaboradores", {
            detail: {
                operacao: "edit",
                id_colab_fran: idf
            }
        });
        window.dispatchEvent(event);
    }

    function ListarColaboradores(id_usuario, pagina, qtd_reg_pagina) {

        api.get("/colaboradores_listar", { params: { id_usuario, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarColaboradorNome() {

        api.get("/colaboradores_busca/", { params: { busca } })
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarColaboradorCpf() {

        api.get("/colaboradores_busca_cpf/", { params: { buscaCpf } })
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function AltStatusColaborador(id) {
        let id_colab_fran = id;
        const param2 = {
            status_colaborador: "I",
        }
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão do Colaborador?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/colaboradores_edit_status/" + id_colab_fran, param2)
                            .then((resp) => {
                                if (resp.status === 200) {
                                    localStorage.setItem('id_colaborador', '0');//para controle de listagem ao ser removido item
                                    ListarColaboradores(localStorage.getItem("id_usuario"), 1, 100);
                                } else {
                                    alert("Erro ao excluir dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao excluir dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function AtualizaTab() {
        setBusca("");
        ListarColaboradores(localStorage.getItem("id_usuario"), 1, 100);
    }


    function ChangePerRows(newQtd, page) {
        ListarColaboradores(localStorage.getItem("id_usuario"), page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarColaboradores(localStorage.getItem("id_usuario"), page, qtd_reg_pagina);
    }

    function RefreshDados() {
        BuscarColaboradorId();
    }

    function BuscarColaboradorId() {

        let id_colab_fran = localStorage.getItem('id_colaborador');
        api.get("/colaboradores_id/" + id_colab_fran)
            .then((resp) => {
                var dados2 = []
                dados2.push({ //montando o array com o objeto recebido
                    'id_colab_fran': resp.data.id_colab_fran,
                    'nome_colaborador': resp.data.nome_colaborador,
                    'cel_colaborador': resp.data.cel_colaborador,
                    'cpf_colaborador': resp.data.cpf_colaborador
                })
                setDados(dados2);
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
                console.log(err);
            });
    }

    useEffect(() => {
        if (busca === "") {
            // ListarColaboradores(localStorage.getItem("id_usuario"), 1, qtd_reg_pagina);

        } else {
            PesquisarColaboradorNome();
        }

    }, [busca]);

    useEffect(() => {

        if (buscaCpf === "") {
            // ListarColaboradores(localStorage.getItem("id_usuario"), 1, qtd_reg_pagina);

        } else {
            PesquisarColaboradorCpf();
        }

    }, [buscaCpf]);

    useEffect(() => {
        localStorage.setItem("id_usuario", 1)

        let id_temp_col = localStorage.getItem('id_colaborador');
        if (id_temp_col === "0") {
            ListarColaboradores(localStorage.getItem("id_usuario"), 1, 100);
        } else {
            function esperaAtualizar() {
                BuscarColaboradorId(); // pesquisa o fornecedor pelo id armazenado no storage
            }
            setTimeout(esperaAtualizar, 500); // Executará esperaAtualizar após meio segundo  
        }

    }, []);

    return <>
        {
            ifLogado !== "notLogad" ? <>
                <ColaboradoresCad onClose={RefreshDados} />
                <div className="container-fluid margem-top-col ms-5">
                    <Navbar tela="colaboradores" />
                    <div className="row flex-nowrapl me-5 ">
                        <div className="col py-3 me-3">
                            <div className="input-group justify-content-end">
                                <input onChange={(e) => setBusca(e.target.value)} type="text" className="form-control buscainput-col" id="pesq" placeholder="Pesquisar colaborador" value={busca} />
                                <button onClick={AtualizaTab} className="btn btn-primary mb-2" type="button" id="button-addon2">Mostrar Todos</button>
                                <button onClick={EditarColaboradorSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" id="button-addon2 "></button>
                            </div>
                            <div className="bg-white p-4 rounded-4 border ">
                                <div className="row d-flex mb-3 ">
                                    <div className="d-flex justify-content-end">
                                        <div className="d-flex">
                                            <div className="d-flex">
                                                <small className="text-secundary fw-bold mt-2 me-1 ">CPF</small>
                                                <input type="text" className="form-control cnpj-col" onChange={(e) => setBuscaCpf(e.target.value)} value={buscaCpf} placeholder="999.999.999-99" />
                                            </div>
                                            <button className="btn btn-danger "><i className="bi bi-file-earmark-pdf "></i>Imprimir</button>
                                            {
                                                usuario === "ariscoz@gmail.com" ?
                                                    <button onClick={NovoColaborador} className="btn btn-primary ms-3">Novo</button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <DataTable className="tabela-items-tabCol" columns={columns}
                                    data={dados}
                                   // pagination={true}
                                   // paginationComponentOptions={paginationOptions}
                                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                                   // paginationServer={true}
                                    //paginationTotalRows={total_registros}
                                    //onChangeRowsPerPage={ChangePerRows}
                                    //onChangePage={ChangePage}
                                />
                            </div>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default Colaboradores;