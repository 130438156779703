import { Dock } from "react-dock";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api.js";
import "./franqueados-cad.css";

import generatePDF, { Margin } from 'react-to-pdf';

import { dadosLogin } from "../../zustand/zuztand.js"


function FranqueadosCad(props) {
    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [id_franqueado, setIdFranqueado] = useState(0);
    const [nome_franqueado, setNome_franqueado] = useState("");
    const [data_nasc_franqueado, setData_nasc_franqueado] = useState("");
    const [data_cad_franqueado, setData_cad_franqueado] = useState("");
    const [cpf_franqueado, setCpf_franqueado] = useState("");
    const [cert_franqueado, setCert_franqueado] = useState("");
    const [rg_franqueado, setRg_franqueado] = useState("");
    const [sexo_franqueado, setSexo_franqueado] = useState("");
    const [end_franqueado, setEnd_franqueado] = useState("");
    const [cep_franqueado, setCep_franqueado] = useState("");
    const [bairro_franqueado, setBairro_franqueado] = useState("");
    const [cidade_franqueado, setCidade_franqueado] = useState("");
    const [comp_franqueado, setComp_franqueado] = useState("");
    const [uf_franqueado, setUf_franqueado] = useState("");
    const [obs_franqueado, setObs_franqueado] = useState("");
    const [data_saida_franqueado, setData_saida_franqueado] = useState("");
    const [motivo_franqueado, setMotivo_franqueado] = useState("");
    const [base64Image, setBase64Image] = useState("");
    const [foto_franqueado, setFoto_Franqueado] = useState("");
    const [num_franqueado, setNum_Franqueado] = useState("");
    const [nome_franquias, setNome_franquias] = useState("");
    const [tel_franqueado, setTel_franqueado] = useState("");

    const id_temp = localStorage.getItem('id_franqueado'); //para montar o id na tela edit
    const texto2 = ("0000" + id_temp).slice(-4);// incluir zeros esquerda
    const textonovo = `- Cód: ${texto2}`; //para montar o id na tela edit

    const { usuario } = dadosLogin()


    const personalizacao = { //para impressão pdf
        // Baixar/Salvar = save / Abrir no navegador = open
        method: 'open',
        page: {
            // Definir a margem: SMALL ou MEDIUM 
            margin: Margin.SMALL,
            // Formato da página: A4 ou letter
            format: 'A4',
            // Orientação do arquivo: portrait ou landscape
            orientation: 'portrait',
        },
    }

    const recuperarConteudoParaPDF = () => document.getElementById('conteudo');

    function getCep() {
        const cepAlu = cep_franqueado.replace(/\D/g, '')
        if (cep_franqueado.length !== 8) {
            alert("CEP inválido.");

        } else {
            fetch(`https://viacep.com.br/ws/${cep_franqueado}/json/`).then(res => res.json()).then(data => {
                //  console.log(data);

                if (data.erro) {
                    alert("CEP não encontrado.")
                } else {

                    setEnd_franqueado(data.logradouro);
                    // localStorage.setItem('Rua', data.logradouro);
                    setBairro_franqueado(data.bairro);
                    // localStorage.setItem('Bairro', data.bairro);
                    setCidade_franqueado(data.localidade);
                    //localStorage.setItem('Local', data.localidade);

                    // setCep(data.cep);
                    setUf_franqueado(data.uf);
                }
            });
        }
    }

    function ChangeDataNascFranqueado(e) {
        let ano = e.target.value.substring(0, 4);
        let mes = e.target.value.substring(5, 7);
        let dia = e.target.value.substring(8, 11);
        setData_nasc_franqueado(dia + '/' + mes + '/' + ano);
    }


    function Limpar() {
        setNome_franqueado("");
        setEnd_franqueado("");
        setData_nasc_franqueado("");
        setCpf_franqueado("");
        setRg_franqueado("");
        setCert_franqueado("");
        setData_nasc_franqueado("");
        setData_cad_franqueado("");
        setRg_franqueado("");
        setSexo_franqueado("");
        setCep_franqueado("");
        setBairro_franqueado("");
        setCidade_franqueado("");
        setComp_franqueado("");
        setUf_franqueado("");
        setObs_franqueado("");
        setData_saida_franqueado("");
        setMotivo_franqueado("");
        setFoto_Franqueado("");
        setNum_Franqueado("");
        setNome_franquias("");
        setTel_franqueado("");
        setBase64Image(localStorage.getItem('novo_franqueado'));

    }


    function SalvarDadosFranqueado() {
        // setStatus_edit(0);

        if (nome_franqueado === "" || cpf_franqueado === "") {
            alert("Campo nome e cpf são obrigatórios")
        } else {
            let fotofranq = localStorage.getItem('novo_franqueado');
            // setBase64Image(localStorage.getItem('novo_franqueado'))


            let dataAtual = new Date();
            var ver_mes = new Date(dataAtual);
            var month = localStorage.setItem('mesCad', ver_mes.getMonth());//pega o mes atual não é somado 1 por causa da logica controle mensalidade

            let anoAtualiz = localStorage.setItem('anoAtualiz', dataAtual.getFullYear()); // pega ano atual
            let status_franqueado = "A"
            const params = {

                nome_franqueado,
                data_nasc_franqueado,
                cpf_franqueado,
                rg_franqueado,
                obs_franqueado,       
                end_franqueado,
                cep_franqueado,
                status_franqueado,
                id_usuario: localStorage.getItem("id_usuario"),
                cidade_franqueado,
                bairro_franqueado,
                comp_franqueado,
                uf_franqueado,
                data_saida_franqueado,
                motivo_franqueado,
                foto_franqueado: fotofranq,
                num_franqueado,
                tel_franqueado,
                sexo_franqueado,
                nome_franquias,
            };

            const params2 = {

                nome_franqueado,
                nome_franquias,
                tel_franqueado,
                data_nasc_franqueado,
                cpf_franqueado,
                rg_franqueado,
                cep_franqueado,
                end_franqueado,
                num_franqueado,
                cidade_franqueado,
                bairro_franqueado,
                comp_franqueado,
                uf_franqueado,
                obs_franqueado,
                sexo_franqueado,
                data_saida_franqueado,
                motivo_franqueado,
            };
            if (operacao === "new") {
                // NOVO REGISTRO........................
                api.post("/franqueados_insert", params)
                    .then((resp) => {

                        if (resp.status === 201) {
                            setShow(false);
                            props.onClose(); //para atualizar a tabela funcao
                        } else {
                            alert("Erro ao inserir dados");
                        }
                    })
                    .catch((err) => {
                        // setDados([]);
                        alert("Erro ao inserir dados");
                    });

            } else {
                // setStatus_edit(1)

                if (nome_franqueado === "" || cpf_franqueado === "") {
                    alert("Campo nome, e cpf são obrigatórios")
                } else {

                    // EDITAR REGISTRO EXISTENTE........................
                    let id = parseInt(localStorage.getItem("id_franqueado"))

                    api.put("/franqueados_edit/" + id, params2)
                        .then((resp) => {
                            if (resp.status === 200) {
                                alert("Registro editado com sucesso!")
                                setShow(false);
                                props.onClose();
                            } else {
                                alert("Erro ao editar dados");
                            }
                        })
                        .catch((err) => {
                            alert("Erro ao editar dados");
                        });
                }
            }
        }

    }

    useEffect(() => {

        window.addEventListener('openFranqueados', (param) => {

            if (param.detail.operacao === "new") {
                Limpar();

            } else {
                Limpar();

                localStorage.setItem('id_franqueado_detail', param.detail.id_franqueado)

                api.get("/franqueados_id/" + param.detail.id_franqueado)
                    .then((resp) => {

                        setIdFranqueado(resp.data.id_franqueado);
                        setNome_franqueado(resp.data.nome_franqueado);
                        setEnd_franqueado(resp.data.end_franqueado);
                        setData_nasc_franqueado(resp.data.data_nasci_franqueado)
                        setCpf_franqueado(resp.data.cpf_franqueado)
                        setRg_franqueado(resp.data.rg_franqueado);
                        setCert_franqueado(resp.data.cert_franqueado);
                        setData_nasc_franqueado(resp.data.data_nasc_franqueado);
                        setRg_franqueado(resp.data.rg_franqueado);
                        setSexo_franqueado(resp.data.sexo_franqueado);
                        setCep_franqueado(resp.data.cep_franqueado);
                        setBairro_franqueado(resp.data.bairro_franqueado);
                        setCidade_franqueado(resp.data.cidade_franqueado);
                        setComp_franqueado(resp.data.comp_franqueado);
                        setUf_franqueado(resp.data.uf_franqueado);
                        setObs_franqueado(resp.data.obs_franqueado);
                        setData_saida_franqueado(resp.data.data_saida_franqueado);
                        setMotivo_franqueado(resp.data.motivo_franqueado);
                        setNum_Franqueado(resp.data.num_franqueado);
                        setTel_franqueado(resp.data.tel_franqueado);
                        setNome_franquias(resp.data.nome_franquias);

                        let dataCad = (resp.data.data_cad_franq); //para converter a data no padrao br
                        let ano = dataCad.substring(0, 4);
                        let mes = dataCad.substring(5, 7);
                        let dia = dataCad.substring(8, 10);
                        setData_cad_franqueado(dia + '/' + mes + '/' + ano);

                        setBase64Image(resp.data.foto_franqueado);



                    })
                    .catch((err) => {
                        alert("Erro ao carregar dados");
                        console.log(err);
                    });
            }
            setShow(true);
            setOperacao(param.detail.operacao);

        });
    }, []);




    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setBase64Image(e.target.result);
                //console.log(base64Image)                   //imagem convertida em base64
            };

            reader.readAsDataURL(file);
        }
    };

    function PesquisarFotoFranqueado() {

        let id_franqueado = localStorage.getItem('id_franqueado');
        setBase64Image("");

        api.get("/franqueados_foto/" + id_franqueado)
            .then((resp) => {

                setBase64Image(resp.data.foto_franqueado);
                //console.log(resp.data.foto_franqueado)      

            })
            .catch((err) => {
                setBase64Image([]);
                alert("Erro ao carregar franqueado");
            });
    }


    function SalvarDados() {

        let id_franqueado = localStorage.getItem("id_franqueado")

        if (id_franqueado === "") {
            alert("Erro ao selecionar franqueado")
        } else {

            const params2 = {
                foto_franqueado: base64Image,


            };
            //console.log(base64Image)
            //console.log(id_franqueado)


            api.put("/franqueados_edit_foto/" + id_franqueado, params2)
                .then((resp) => {
                    if (resp.status === 200) {
                        alert("Sucesso");

                    } else {
                        alert("Erro ao editar dados");
                    }
                })
                .catch((err) => {

                    alert("Erro ao editar dados");
                });

        }
    }


    return <Dock position="right"
        isVisible={show}
        fluid={false}
        size={1220}
        onVisibleChange={(visible) => {
            setShow(visible);
        }}
    >

        <div className="container-fluid fundo-dock p-3" id="conteudo">
            <h4 className="d-inline  "> {operacao === "edit" ? "Editar Franqueado " + textonovo : "Novo Franqueado"} </h4>

            <div className="row d-flex mt-4">
                <div className="col-3 ">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"><img className='imagem_foto-franqueados' src={base64Image} alt="Imagem Selecionada" /></button>


                </div>
                <div className="col-4 ">
                    <small className="text-secondary">Cpf</small>
                    <input type="text" className="form-control cpf" onChange={(e) => setCpf_franqueado(e.target.value)} value={cpf_franqueado} />
                    <small className="text-secondary">Rg</small>
                    <input type="text" className="form-control cpf" onChange={(e) => setRg_franqueado(e.target.value)} value={rg_franqueado} />
                    <small className="text-secondary">Data Cadastro</small>
                    <div className="input-group mb-3 blocoIcones_aluno2 ">
                        <input type="text" className="form-control dat-cad-fra " id="birth" value={data_cad_franqueado} readonly />
                    </div>
                </div>
                <div className="col-4 ">
                    <small className="text-secondary">Data Nascimento</small>
                    <div className="input-group mb-3 blocoIcones_fran ">
                        <input type="text" className="form-control " id="birth" value={data_nasc_franqueado} />
                        <input type="date" className="form-control dt_nac-btn" onChange={ChangeDataNascFranqueado} />
                    </div>
                    <small className="text-secondary">Sexo</small>
                    <select type="text" className="form-control sexo-fran " onChange={(e) => setSexo_franqueado(e.target.value)} value={sexo_franqueado} >
                        <option>Selecione</option>
                        <option>Masculino</option>
                        <option>Feminino</option>
                        <option>Outro</option>
                    </select>
                    <small className="text-secondary blocoIcones">Tel.</small>
                    <input type="text" className="form-control sexo-fran" onChange={(e) => setTel_franqueado(e.target.value)} value={tel_franqueado} />
                </div>
            </div>

            <div className="row d-flex mt-5">
                <div className="col-8 ">
                    <small className="text-secondary">Nome Franqueado</small>
                    <input type="text" className="form-control " onChange={(e) => setNome_franqueado(e.target.value)} value={nome_franqueado} />
                </div>
                <div className="col-2 ">
                </div>
                <div className="col-2 ">
                    <small className="text-secondary blocoIcones">CEP:</small>
                    <div className="col-11-neg blocoIcones ">
                        <input type="text" className="form-control input-cep-fran" onChange={(e) => setCep_franqueado(e.target.value)} value={cep_franqueado} />
                        <button className="btn-cep-fran" onClick={getCep}><i className=" bi bi-search btn-cep-fran"></i></button>
                    </div>
                </div>
            </div>

            <div className="row d-flex ">
                <div className="col-8 ">
                    <small className="text-secondary">Rua/Av.</small>
                    <input type="text" className="form-control" onChange={(e) => setEnd_franqueado(e.target.value)} value={end_franqueado} />
                </div>
                <div className="col-2 ">
                    <small className="text-secondary">Número</small>
                    <input type="text" className="form-control numero-cr" onChange={(e) => setNum_Franqueado(e.target.value)} value={num_franqueado} />
                </div>
                <div className="col-2 ">
                    <small className="text-secondary">UF</small>
                    <select type="text" className="form-control numero-cr" onChange={(e) => setUf_franqueado(e.target.value)} value={uf_franqueado} >
                        <option value="">Selecione</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                    </select>
                </div>
            </div>

            <div className="row d-flex ">
                <div className="col-6 ">
                    <small className="text-secondary">Bairro</small>
                    <input type="text" className="form-control cidade-cr" onChange={(e) => setBairro_franqueado(e.target.value)} value={bairro_franqueado} />
                </div>
                <div className="col-6 ">
                    <small className="text-secondary">Cidade</small>
                    <input type="text" className="form-control cidade-cr" onChange={(e) => setCidade_franqueado(e.target.value)} value={cidade_franqueado} />
                </div>
            </div>

            <div className="row d-flex ">
                <div className="col-6 ">
                    <small className="text-secondary">Complemento</small>
                    <input type="text" className="form-control " onChange={(e) => setComp_franqueado(e.target.value)} value={comp_franqueado} />
                </div>
                <div className="col-6 ">
                    <small className="text-secondary">Obs:</small>
                    <input type="text" className="form-control " onChange={(e) => setObs_franqueado(e.target.value)} value={obs_franqueado} />
                </div>
            </div>

            <div className="row d-flex ">
                <div className="col-12">
                    <small className="text-secondary">Franquias Ativas:</small>
                    <input type="text" className="form-control " onChange={(e) => setNome_franquias(e.target.value)} value={nome_franquias} />
                </div>
            </div>

            <div className="row d-flex">
                <div className="col-2">
                    <small className="text-secondary">Data Encerramento</small>
                    <input type="text" className="form-control " onChange={(e) => setData_saida_franqueado(e.target.value)} value={data_saida_franqueado} />
                </div>
                <div className="col-10">
                    <small className="text-secondary">Motivo</small>
                    <input type="text" className="form-control " onChange={(e) => setMotivo_franqueado(e.target.value)} value={motivo_franqueado} />
                </div>
            </div>


            <div className="row d-flex">
                <div className="col-3">

                </div>
                <div className="col-6 div-btns2">
                    <div className="d-flex justify-content-center">
                        <button onClick={() => generatePDF(recuperarConteudoParaPDF, personalizacao)} className="btn btn-danger me-3"><i className="bi bi-file-earmark-pdf "></i>Imprimir</button>
                        <button className="btn btn-outline-primary btn-franqueados" onClick={() => setShow(false)}>Cancelar</button>
                        {
                            usuario === "ariscoz@gmail.com" ?
                            <button className="btn btn-primary ms-3" onClick={SalvarDadosFranqueado}>Salvar Dados</button> : null
                        }                      
                       
                    </div>
                </div>
                <div className="col-3">
                </div>
            </div>

            <div className="container-fluid sidebar p-3 ">
                <div className="row align-items-end footer ">
                    <div className="text-center">

                    </div>
                </div>
            </div>

            <div>
            </div>
        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Foto</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div >
                            <input type="file" accept="image/*" onChange={handleImageUpload} />

                            {base64Image && (<div><img className='imagem_foto' src={base64Image} alt="Imagem Selecionada" /></div>)}
                            <small className="text-secondary">Id :</small>
                            <small className="text-secondary">{id_franqueado}</small>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        {
                            usuario === "ariscoz@gmail.com" ?
                            <button onClick={SalvarDados} type="button" className="btn btn-primary">Savar Foto</button> : null
                        }

                    </div>
                </div>
            </div>
        </div>

    </Dock>

}

export default FranqueadosCad;