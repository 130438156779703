import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../../pages/fornecedores-contratos/fornecedores-contratos.css"
import { Link } from "react-router-dom";

import AutocompleteFornecedoresContratos from "../../components/autocomplete-fornecedores-contratos/autocomplete-fornecedores-contratos.jsx";

function FornecedoresContratos() {

  const [dados, setDados] = useState([]);
  const [url_doc_fornecedor, setUrl_doc_fornecedor] = useState("");
  const [dadosCombo, setDadosCombo] = useState([]);

  const [codigo, setCodigo] = useState(0);
  const [nome_fornecedor, setNome_fornecedor] = useState("");
  const [texto, setTexto] = useState("");
  const [escolha, setEscolha] = useState(true);

  function PesquisarImgContrato() {//pesquisa ao selecionar a fornecedor no combobox antes do autocomplete

    let id_fornecedor = localStorage.getItem('id_fornecedor_pesq');

    api.get("/fornecedores_contrato_url", { params: { id_fornecedor } })
      .then((resp) => {
        setDados(resp.data);
        //console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  function PesquisarImgContratoDigitado() {//pesquisa pelo nome da franquia selecionado no autocomplete

    let id_fornecedor = codigo;
    api.get("/fornecedores_contrato_url", { params: { id_fornecedor } })
      .then((resp) => {
        setDados(resp.data);
        //console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  function ChangeUrl(e) {
    setUrl_doc_fornecedor(e.target.value);
  }

  function ChangeId_fornecedor(e) {
    setUrl_doc_fornecedor("");
    setDados([])
    localStorage.setItem('id_fornecedor_pesq', e.target.value);
    PesquisarImgContrato();
  }

  function ListarFornecedorGeralNome() {//somente id e nome para montar combo c

    api.get("/fornecedores_contratos_listar")
      .then((resp) => {
        let novoArray = []//  par filtrar os fornecedores repetidos com join db-contratos-fornecedores
        novoArray = resp.data.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null))
        setDadosCombo(novoArray);
      })
      .catch((err) => {
        setDadosCombo([])
        alert("Erro ao montar combo");
      });
  }

  function escolher() { // para alterar o estado escolha e esconder imput
    setUrl_doc_fornecedor('');
    setEscolha(!escolha);
  }

  useEffect(() => {
    ListarFornecedorGeralNome(); //carrega o combo ao iniciar a tela somente com os fornecedores que tem contratos

  }, []);

  useEffect(() => {
    PesquisarImgContratoDigitado(); // carrega o combo com os fornecedores que tem contratos  aoselecionar no combo autocomplete

  }, [codigo]);

  return <>
    <div className="d-flex justify-content-center container-fundo-col-contratos">
      <div className="d-inline justify-content-center container-tela-col-contratos">
        <div className="d-flex col-11 mt-3" >
          <div className="d-flex col-12">
            <div className="col-2 ms-3">
              <button className="btn btn-outline-primary ms-4 " onClick={escolher}>Digitar/Selecionar</button>
            </div>
            {
              escolha === true ?
                <div className="col-4">
                  < AutocompleteFornecedoresContratos placeholder="Digite o nome do fornecedor...."
                    onClickId={setCodigo} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                    onClickNome={setNome_fornecedor} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                    onClickTexto={setTexto} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                    onClickUrl={setUrl_doc_fornecedor}
                    value={nome_fornecedor}
                  />
                </div> : null
            }

            {
              escolha === false ?
                <div className="col-4 ">
                  <select className="form-control " name="url" id="url" onChange={ChangeId_fornecedor}>
                    <option value="">Selecione o fornecedor</option>
                    {
                      dadosCombo.map((item) => {
                        return <option key={item.id_fornecedor} value={item.id_fornecedor}>{item.id_fornecedor + ' - ' + item.nome_fornecedor}</option>
                      })
                    }
                  </select>
                </div> : null
            }

            <div className="col-2 ms-4 mb-3">
              <select className="form-control " name="url" id="url" onChange={ChangeUrl}>
                <option value="">Selecione o contrato</option>
                {
                  dados.map((item) => {
                    return <option key={item.id_contrato_fornecedor} value={item.url_contrato_forn}>{item.descr_contrato}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div className="col-2 ms-3">
            <Link to="/menu-fornecedores" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
          </div>
        </div>
        <div className="d-flex justify-content-center container-tela-col-contratos overflow-auto">

          <div className="  d-block">
            <div className=" col-11">
              <div>
                <iframe className=" col-11 frame-contratos-2 ms-4" src={url_doc_fornecedor}></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
}
export default FornecedoresContratos;