import { useEffect, useState } from "react";
import api from "../../services/api.js";
import btnClear from "../../assets/clear.png";
import "../autocomplete-colaboradores/autocomplete-colaboradores.css"

function AutocompleteColaboradores(props) {

    const [texto, setTexto] = useState("");
    const [dadosColaboradores, setDadosColaboradores] = useState([]); //todas 

    function ListarColaboradoresGeral(busca) {//somente id e nome para montar combo

        if (busca.length > 2) {
            api.get("/colaboradores_busca/", { params: { busca } })
                .then((resp) => {
                    setDadosColaboradores(resp.data);
                    //console.log(resp.data)
                })
                .catch((err) => {
                    setDadosColaboradores([]);
                    alert("Erro ao carregar dados");
                });
        }
    }

    function Filtrar(e) {
        setTexto(e.target.value);
        ListarColaboradoresGeral(e.target.value);
    }

    function SelecionarColaborador(id, nome_colaborador) {   
        setTexto(nome_colaborador); //para preencher o input de busca
        setDadosColaboradores([]); //fecha caixa relaçao forn 
        props.onClickId(id); // para enviar o id para a tela colaboradores
        props.onClickNome(nome_colaborador);
    }

    function Clear() {
        setTexto("");
        props.onClickId(0);
        props.onClickNome("");
        props.onClickUrl(""); //para limpar url do frame
        setDadosColaboradores([]);
    }

    useEffect(() => {
        document.addEventListener('click', (e) => setDadosColaboradores([])); // quando clicar fora da tela de resultado fecha a mesma zerando o array
    }, []);

    return <div className="autocomplete-col col-12">
        <input type="text" className="form-control" onChange={Filtrar}
            placeholder={props.placeholder} value={texto} />
        {
            texto.length > 0 ?
                <button className="autocomplete-col-clear" onClick={Clear}>
                    <img src={btnClear} className="autocomplete-col-img" />
                </button>
                : null
        }

        {
            dadosColaboradores.length > 0 ?
                <div className="autocomplete-col-items">
                    {
                        dadosColaboradores.map((item) => {
                            return <div key={item.id_colab_fran}
                                className="autocomplete-forn-item"
                                onClick={(e) => SelecionarColaborador(item.id_colab_fran, item.nome_colaborador)}
                            >
                                <b>{item.nome_colaborador}</b><br />
                                <small className="text-secondary">Código: {item.id_colab_fran}</small><br />
                            </div>
                        })
                    }
                </div> : null
        }
    </div>
}

export default AutocompleteColaboradores;