import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand";
import { useNavigate } from "react-router-dom";

import "../menucalendario/menu-calendario.css";
import { Link } from "react-router-dom";


function MenuCalendario(props) {
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin();
    return <>
        {
            ifLogado !== "notLogad" ? <>
                <div className="d-flex container-fundo-menu-calen">
                    <Navbar tela="calendario" />
                    <div className="row container-body-menu--calen">
                        <div className="conteudo fixed-bottom">

                            <div className="d-flex ms-5 justify-content-between">
                                <div className="col-2 mb-2">
                                    <Link to="/calendario-aniver" className="btn-orange-plus" aria-current="page">Datas comemorativas</Link>
                                </div>
                                <div className="col-2 mb-2 ms-3">
                                    <Link to="/calendario-ferias" className="btn-orange-plus" aria-current="page">Férias</Link>
                                </div>
                                <div className="col-2 mb-2 ms-3">
                                    <Link to="/calendario-convencao" className="btn-orange-plus" aria-current="page">Convenção</Link>
                                </div>
                                <div className="col-2 mb-2 ms-3">
                                    <Link to="/calendario-inalguracoes" className="btn-orange-plus" aria-current="page">Inalgurações</Link>
                                </div>
                                <div className="col-2 mb-2 ms-3">
                                    <Link to="/calendario-geral" className="btn-orange-plus" aria-current="page">Eventos</Link>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default MenuCalendario;