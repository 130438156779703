import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/pt-br'
import api from "../../services/api.js";
import { dadosLogin } from "../../zustand/zuztand.js"

import "../aniversario-colaboradores/aniversario-colaboradores.css";

function AniversarioColaboradores() {
  const navigate = useNavigate();
  const { ifLogado, usuario, nivel_user } = dadosLogin()

  const [dados, setDados] = useState([]);
  const local = moment().locale('pt-br').format('LL'); //converte a data par pt-br
  const local_novo = local.slice(5); // separa o texto inicial ex 12 se setembro de 2024 ficando somente setembro...

  useEffect(() => {

    var mes = new Date();
    var month = mes.getMonth();
    var mesAtual = month + 1;

    let novo_array = [];

    api.get("/col_aniver_listar")
      .then((resp) => {
        //console.log(resp.data)
        resp.data.map((item) => {
          let dtaAniver = parseInt(item.data_nasc_col.substring(3, 5));

          if (dtaAniver === mesAtual) {
            novo_array.push({
              nome: item.nome_colaborador,
              data: item.data_nasc_col,
              img: item.foto_aniver
            })
          }
        })
        //console.log(novo_array)
        setDados(novo_array);
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });

  }, []);

  return <>
   {
          ifLogado !== "notLogad" ? <>
 
    <div className="d-flex container-fundo-col">
      <div className="d-block container-body-col">
        <div className="d-flex justify-content-between">
          <div className="col-1">
          </div>
          <h4 className="ms-3 mt-2">
            Aniversariantes: {local_novo}
          </h4>
          <div className="col-1 ">
            <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 btn-sm" >Voltar </Link>
          </div>
        </div>
        <div className="d-flex flex-row p-2 div-cards">
          {
            dados.map((item) => {
              return <div key={item.id_colab_fran} className="d-flex ">
                <div className="col-10">

                  {
                    item.img?
                    <img src={item.img} className=" img-card-col ms-3" alt="..." />: <img src="https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/colaboradores%2Ffoto_aniver%2Faniver_padrao.jpg?alt=media&token=e8c4419a-0d2b-4c0c-ac7c-0926bd8b848f" className=" img-card-col ms-3" alt="..." />
                  }
  
                  <div className="ms-3 mt-2 d-flex">
                    <p className="text-dark">{item.nome}</p>
                  </div>
                  <div className="ms-3  d-flex">
                    <p className="text-secondary">{item.data}.</p>
                  </div>
                </div>                
              </div>
            })
          }
        </div>
      </div>
    </div>
    </> :navigate('/login')
        }
  </>
}
export default AniversarioColaboradores;