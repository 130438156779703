import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../franquias-contratos/franquias-contratos.css"
import AutocompleteFranquia from "../../components/autocomplete-franquia/autocomplete-franquia.jsx";

import { Link } from "react-router-dom";

function FranquiasContratos() {

  const [dados, setDados] = useState([]);
  const [url_doc_franquia, setUrl_doc_franquia] = useState("");
  const [dadosCombo, setDadosCombo] = useState([]);

  const [codigo, setCodigo] = useState(0);
  const [razao_franquia, setRazao_franquia] = useState("");
  const [texto, setTexto] = useState("");
  const [escolha, setEscolha] = useState(true);

  const [cidade_franquia, setCidade_franquia] = useState("");
  const [textoCidade, setTextoCidade] = useState("");
  const [dadosFranquiasCidades, setDadosFranquiasCidades] = useState([]);
  const [buscaAbrang, setBuscaAbrang] = useState("");


  function PesquisarImgContrato() {//pesquisa ao selecionar a franquia no combobox

    let id_franquia = localStorage.getItem('id_franquia_pesq');
    api.get("/franquias_doc_url", { params: { id_franquia } })
        .then((resp) => {
            setDados(resp.data);
            // console.log(resp.data)
        })
        .catch((err) => {
            setDados([]);
            alert("Erro ao carregar dados");
        });
}

function PesquisarImgContratoDigitado() {//pesquisa pelo nome da franquia digitado

    let id_franquia = codigo;
    api.get("/franquias_doc_url", { params: { id_franquia } })
        .then((resp) => {
            setDados(resp.data);
            // console.log(resp.data)
        })
        .catch((err) => {
            setDados([]);
            alert("Erro ao carregar dados");
        });
}

function ChangeUrl(e) {
    setUrl_doc_franquia(e.target.value);
}

function ChangeId_franquia(e) {
    setUrl_doc_franquia("");
    localStorage.setItem('id_franquia_pesq', e.target.value);
    PesquisarImgContrato();
}

function ListarFranquiasGeralNome() {//somente id e nome para montar combo

    api.get("/franquias_listar_geral_nome")
        .then((resp) => {
            //console.log(resp.data)
            setDadosCombo(resp.data);
        })
        .catch((err) => {
            alert("Erro ao montar combo");
        });
}
function escolher() { // para alterar o estado escolha e esconder imput
    setUrl_doc_franquia('')
    setEscolha(!escolha)
    setDadosFranquiasCidades([])
    setBuscaAbrang("")
    setDados([])


}

function ListarFranquiasCidade() {//somente id e nome para montar combo
    setDadosFranquiasCidades([])
    api.get("/franquia_busca_abrang/", { params: { buscaAbrang } })
        .then((resp) => {
            setDadosFranquiasCidades(resp.data);
            // console.log(resp.data)
        })
        .catch((err) => {
            setDadosFranquiasCidades([]);
            alert("Erro ao carregar dados");
        });
}

useEffect(() => {
    ListarFranquiasGeralNome();
}, []);

useEffect(() => {
    PesquisarImgContratoDigitado();
}, [codigo]);

useEffect(() => {
    ListarFranquiasCidade();
}, [buscaAbrang]);

return <>
<div className="d-flex justify-content-center container-fundo-fran-cont">
    <div className="d-inline justify-content-center  container-body-fran-cont">
        <div className="d-flex  mt-3" >
            <div className="d-flex ">
                <div className="col-2 ms-2 ">
                    <button className="btn btn-outline-primary ms-3" onClick={escolher}>Digitar/Selecionar</button>
                </div>
                {
                    escolha === true ?
                        <div className="col-2">
                            < AutocompleteFranquia placeholder="Nome da franquia...."
                                onClickId={setCodigo} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                onClickNome={setRazao_franquia} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                onClickTexto={setTexto} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                onClickUrl={setUrl_doc_franquia}
                                onClickCidade={setCidade_franquia}
                                value={razao_franquia}
                            />
                        </div> : null
                }

                {
                    escolha === false ?
                        <div className="col-2 ">
                            <select className="form-control " name="url" id="url" onChange={ChangeId_franquia}>
                                <option value="">Selecione a franquia</option>
                                {
                                    dadosCombo.map((item) => {
                                        return <option key={item.id_franquia} value={item.id_franquia}>{item.razao_franquia + ' - ' + item.cidade_franquia}</option>
                                    })
                                }
                            </select>
                        </div> : null
                }

                <div className="d-flex ms-2 mb-3">
                    <div className="col-6 ms-4">
                        <input type="text" className="form-control" placeholder='Cidade....' value={buscaAbrang} onChange={(e) => setBuscaAbrang(e.target.value)} />
                    </div>
                    <div className="col-8 ms-2 ">
                        <select className="form-control " name="url" id="url" onChange={ChangeId_franquia}>
                            <option value="">Selecione a cidade</option>
                            {
                                dadosFranquiasCidades.map((item) => {
                                    return <option key={item.id_franquia} value={item.id_franquia}>{item.cidade_franquia}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-2 div-selec-cid mb-3">
                    <select className="form-control select-url " name="url" id="url" onChange={ChangeUrl}>
                        <option value="">Selecione o contrato</option>
                        {
                            dados.map((item) => {
                                return <option key={item.id_doc_franquias} value={item.url_doc_franquia}>{item.descr_doc}</option>
                            })
                        }
                    </select>
                </div>

                <div className="col-1 ms-3">
                    <Link to="/menu-franquias" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center  overflow-auto">
            <div className="  d-block">
                <div className=" col-11">
                    <div className="">
                        <iframe className=" col-11 frame-contratos-franquias ms-4 " src={url_doc_franquia}></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div >
</>






 }
export default FranquiasContratos;