import "../franquias-fotos-view/franquias-fotos-view.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL, listAll, list, } from "firebase/storage";
import { storage } from "../../services/firebaseConfig.js";
import { dadosLogin } from "../../zustand/zuztand.js"

import api from "../../services/api.js";


function FranquiasFotosView() {

    const [imageUrls, setImageUrls] = useState([]);
    const [dadosUrl, setDadosUrl] = useState([]);
    const [url_imgagem_franquia, setUrl_imgagem_franquia] = useState("");
    const [pastaStorage, setPastaStorage] = useState("");
    const [atualiza, setAtualiza] = useState(false);
    const [id_pesq, setId_pesq] = useState(parseInt(localStorage.getItem('idFranquia')));
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin();

    const [codigo, setCodigo] = useState(0);
    const [razao_franquia, setRazao_franquia] = useState("");
    const [cidade_franquia, setCidade_franquia] = useState("");
    const [texto, setTexto] = useState("");
    const [escolha, setEscolha] = useState(true);
    const [url_doc_fornecedor, setUrl_doc_fornecedor] = useState("");
    const [dadosFranquiasCidades, setDadosFranquiasCidades] = useState([]);
    const [buscaAbrang, setBuscaAbrang] = useState("");


    function AtualiZaListaUrlIdDigitado() { //atualiza lista vindo do storage
        setImageUrls([])
        const imagesListRef3 = ref(storage, `franquias/arquivos/${localStorage.getItem('id_franquia')}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/

        var dadosUrlDow = []
        listAll(imagesListRef3).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            //setImageUrls(dadosUrlDow)
            setDadosUrl(dadosUrlDow)
            //console.log(dadosUrl)
        });
    }


    function PegarUrl_franquia(url) {
        setUrl_imgagem_franquia(url)
    }

    function ChangePastaStorage(e) {//para selecionar no combobox a pasta onde seará salvo ao arquivo no firebase
        setPastaStorage(e.target.value);
        setAtualiza(!atualiza)
    };

    function ChangeId_pesq(e) {
        setId_pesq(e.target.value);
        localStorage.setItem('id_pesq', e.target.value);
    }
    function usar() {
        setId_pesq(codigo)
        setAtualiza(!atualiza)
        setCidade_franquia("")
        setRazao_franquia("")
        setTexto("")
        ListarFranquiasCidade()
    }


    function ListarFranquiasCidade() {//somente id e nome para montar combo
        setDadosFranquiasCidades([])
        api.get("/franquia_busca_abrang/", { params: { buscaAbrang } })
            .then((resp) => {
                setDadosFranquiasCidades(resp.data);
            })
            .catch((err) => {
                setDadosFranquiasCidades([]);
                alert("Erro ao carregar dados");
            });
    }

    function ChangeId_franquia(e) {
        let yy = e.target.value
        let gg = yy.split('/')
        setCodigo(gg[0])
        setCidade_franquia(gg[1])
        setRazao_franquia(gg[2])
    }

    useEffect(() => {
        const imagesListRef3 = ref(storage, `franquias/arquivos/${localStorage.getItem('id_franquia')}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/
        var dadosUrlDow = []
        setImageUrls([])
        listAll(imagesListRef3).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            setDadosUrl(dadosUrlDow)
            setUrl_imgagem_franquia('')
        });
    }, [atualiza]);

    return <>
        <div className="d-flex justify-content-center container-fundo-foto-cont">
            <div className="d-inline justify-content-center  container-body-foto-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className="col-5 ms-4">
                        <div className="input-group">
                            <small className="form-control" value={localStorage.getItem('id_franquia')}>Cód: {localStorage.getItem('id_franquia')} - {localStorage.getItem('nome_franquia')} </small>
                        </div>
                    </div>

                    <small className="text-white mt-2 ms-5 me-2">Pastas</small>
                                <div className="col-4 div-select-pasta5 d-flex">
                                    <select type="text" className="form-control" onChange={ChangePastaStorage}>
                                        <option value="">Selecione</option>                                  
                                        <option value="Inalguração">Inalguração</option>
                                        <option value="Eventos">Eventos</option>
                                        <option value="Visitas">Visitas</option>
                                        <option value="Outras">Outras</option>
                                    </select>
                                    <span className="input-group-addon">
                                        <button className="bi bi-arrow-clockwise btn-id-select text-white" onClick={AtualiZaListaUrlIdDigitado}>
                                        </button>
                                    </span>
                                </div>

                    <div className="col-1 ms-3">
                        <Link to="/franquias" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center  overflow-auto div-principal">
            
                        <div className=" col-12 d-flex  ">
                            <div className="frame-fotos-view">
                                <iframe className=" col-11 fotos-view ms-4 mt-4" src={url_imgagem_franquia}></iframe>
                            </div>

                            <div className="col-1 coluna-img">
                            {imageUrls.map((url, index) => {
                                return <>
                                    <div className="d-flex  ms-2  mt-3 " >
                                        <button onClick={() => PegarUrl_franquia(url)}><img className=" d-flex card-franquia me-2  " alt="Imagem" src={url} /></button>
                                    </div>
                                </>
                            })}
                        </div>
                        </div>                    






                  
                </div>
            </div>
        </div >
    </>
}
export default FranquiasFotosView;