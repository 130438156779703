import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dadosLogin } from "../../zustand/zuztand";
import { useNavigate } from "react-router-dom";
import "../aniversarios/aniversarios.css"
import Navbar from "../../components/navbar/navbar.jsx";
import moment from 'moment';

import api from "../../services/api.js";
import DataTable from "react-data-table-component";

import AniversariosFranquiasPDF from "../../report/report.aniversarios/report.aniversarios.franquias.jsx";
import AniversariosColaboradoresPDF from "../../report/report.aniversarios/report.aniversarios.colaboradores.jsx";
import AniversariosFranqueadosPDF from "../../report/report.aniversarios/report.aniversarios.franqueados.jsx";
import AniversariosCidadesPDF from "../../report/report.aniversarios/report.aniversarios.cidades.jsx";

function Aniversarios() {
    const navigate = useNavigate();
    const [dadosCol, setDadosCol] = useState([]);
    const [dadosFranquia, setDadosFranquia] = useState([]);
    const [dadosFranqueado, setDadosFranqueado] = useState([]);
    const [dadosCidades, setDadosCidades] = useState([]);
    const [mesCombobox, setMesCombobox] = useState([]);
    const [controlTab, setControlTab] = useState(false);
    const { ifLogado, usuario } = dadosLogin();


    {/*----------------------------------------------- Colunas Colaboradores Inicio-----------------------------------------*/ }
    const columnsCol = [
        {
            name: 'Cód.',
            selector: row => row.id_colab_fran,
            sortable: true,
            width: "15%",
        },
        {
            name: 'Nome',
            selector: row => row.nome_colaborador,
            sortable: true,
            width: "50%",
            compact: false
        },
        {
            name: 'Data Nasc.',
            selector: row => row.data_nasc_col,
            sortable: true,
            width: "20%",
            compact: false
        },
    ];
    {/*----------------------------------------------- Colunas Colaboradores Fim -----------------------------------------*/ }

    {/*----------------------------------------------- Colunas Franquias Inicio-----------------------------------------*/ }
    const columnsFran = [
        {
            name: 'Cód.',
            selector: row => row.id_franquia,
            sortable: true,
            width: "15%",
        },
        {
            name: 'Nome',
            selector: row => row.razao_franquia,
            sortable: true,
            width: "50%",
            compact: false
        },
        {
            name: 'Data Inalguração.',
            selector: row => row.data_inalg,
            sortable: true,
            width: "30%",
            compact: false
        },
    ];
    {/*----------------------------------------------- Colunas Franquias Fim -----------------------------------------*/ }

    {/*----------------------------------------------- Colunas Franqueados Inicio-----------------------------------------*/ }
    const columnsFranqueados = [
        {
            name: 'Cód.',
            selector: row => row.id_franqueado,
            sortable: true,
            width: "15%",
        },
        {
            name: 'Nome',
            selector: row => row.nome_franqueado,
            sortable: true,
            width: "50%",
            compact: false
        },
        {
            name: 'Data Nasc.',
            selector: row => row.data_nasc_franqueado,
            sortable: true,
            width: "20%",
            compact: false
        },
    ];
    {/*----------------------------------------------- Colunas Franquaeados Fim -----------------------------------------*/ }

    {/*----------------------------------------------- Colunas Cidades Inicio-----------------------------------------*/ }
    const columnsCidades = [
        {
            name: 'Cód.',
            selector: row => row.id_franquia,
            sortable: true,
            width: "15%",
        },
        {
            name: 'Nome',
            selector: row => row.razao_franquia,
            sortable: true,
            width: "30%",
            compact: false
        },
        {
            name: 'Cidade',
            selector: row => row.cidade_franquia,
            sortable: true,
            width: "30%",
            compact: false
        },
        {
            name: 'Data Aniver.',
            selector: row => row.data_aniver_cidade,
            sortable: true,
            width: "20%",
            compact: false
        },
    ];
    {/*----------------------------------------------- Colunas Cidades Fim -----------------------------------------*/ }
    function ChangeMesCombobox(e) { //seleciona o mes para consulta aniversarios colaborador

        setMesCombobox(e.target.value);  // grava o mes selecionado ex=01 , 02, 12

        localStorage.setItem('mes_combobox_col', e.target.value);

        if (e.target.value === '01') {
            localStorage.setItem('mes_combobox_descr', 'Janeiro');
        }
        else if (e.target.value === '02') {
            localStorage.setItem('mes_combobox_descr', 'Fevereiro');
        }
        else if (e.target.value === '03') {
            localStorage.setItem('mes_combobox_descr', 'Março');
        }
        else if (e.target.value === '04') {
            localStorage.setItem('mes_combobox_descr', 'Abril');
        }
        else if (e.target.value === '05') {
            localStorage.setItem('mes_combobox_descr', 'Maio');
        }
        else if (e.target.value === '06') {
            localStorage.setItem('mes_combobox_descr', 'Junho');
        }
        else if (e.target.value === '07') {
            localStorage.setItem('mes_combobox_descr', 'Julho');
        }
        else if (e.target.value === '08') {
            localStorage.setItem('mes_combobox_descr', 'Agosto');
        }
        else if (e.target.value === '09') {
            localStorage.setItem('mes_combobox_descr', 'Setembro');
        }
        else if (e.target.value === '10') {
            localStorage.setItem('mes_combobox_descr', 'Outubro');
        }
        else if (e.target.value === '11') {
            localStorage.setItem('mes_combobox_descr', 'Novembro');
        }
        else if (e.target.value === '12') {
            localStorage.setItem('mes_combobox_descr', 'Dezembro');
        }
        setDadosCidades([]);
        setDadosCol([]);
        setDadosFranqueado([]);
        setDadosFranquia([]);
    }

    function ComboMes() {
        return <>
            <div className="col-6   ">
                <label htmlFor="InputNome2" className=" ">Mês</label>
                <div className="form-control ">
                    <select name="mes" id="mes" onChange={ChangeMesCombobox} value={mesCombobox} >
                        <option value="00">Selecione</option>
                        <option value="01">01-Janeiro</option>
                        <option value="02">02-Fevereiro</option>
                        <option value="03">03-Março</option>
                        <option value="04">04-Abril</option>
                        <option value="05">05-Maio</option>
                        <option value="06">06-Junho</option>
                        <option value="07">07-Julho</option>
                        <option value="08">08-Agosto</option>
                        <option value="09">09-Setembro</option>
                        <option value="10">10-Outubro</option>
                        <option value="11">11-Novembro</option>
                        <option value="12">12-Dezembro</option>
                    </select>
                </div>
            </div>
        </>
    }

    function ListarColaboradores() { // para verificar os aniversários
        setControlTab(!controlTab)
        let mes_relatorio = localStorage.getItem('mes_combobox_col');

        const dataAtual = moment().format('DD-MM-YYYY');
        let diaAtu = dataAtual.substring(0, 2);
        let mesAtu = dataAtual.substring(3, 5);

        let diaAni = ""
        let mesAni = ""
        let conta = 0;
        let arrayCol = []

        api.get("/col_aniver_listar")
            .then((resp) => {
                // console.log(resp.data)
                resp.data.map((item) => {
                    var dat = item.data_nasc_col;
                    diaAni = dat.substring(0, 2);
                    mesAni = dat.substring(3, 5);

                    if (diaAni === diaAtu && mesAni === mesAtu) {
                        conta = conta + 1;
                        arrayCol.push({
                            id_colab_fran: item.id_colab_fran,
                            nome_colaborador: item.nome_colaborador,
                            data_nasc_col: item.data_nasc_col,
                        })
                    } else if (mesAni === mes_relatorio) {
                        arrayCol.push({
                            id_colab_fran: item.id_colab_fran,
                            nome_colaborador: item.nome_colaborador,
                            data_nasc_col: item.data_nasc_col,
                        })
                    }
                })
                if (conta >= 1) {
                    alert("Tem " + conta + " colaborador(es) de aniversário hoje.");
                }
                setDadosCol(arrayCol);
                setControlTab(true);
            })
            .catch((err) => {
                setDadosCol([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFranquias() { // para verificar os aniversários
        setControlTab(!controlTab)

        const dataAtual = moment().format('DD-MM-YYYY');
        let diaAtu = dataAtual.substring(0, 2);
        let mesAtu = dataAtual.substring(3, 5);

        let diaAni = ""
        let mesAni = ""
        let conta = 0;
        let arrayFranquia = []

        api.get("/franquia_aniver_listar")
            .then((resp) => {
                //console.log(resp.data)
                resp.data.map((item) => {
                    var dat = item.data_inalg;
                    diaAni = dat.substring(0, 2);
                    mesAni = dat.substring(3, 5);

                    if (diaAni === diaAtu && mesAni === mesAtu) {
                        conta = conta + 1;
                        arrayFranquia.push({
                            id_franquia: item.id_franquia,
                            razao_franquia: item.razao_franquia,
                            data_inalg: item.data_inalg,
                        })
                    } else if (mesAni === mesCombobox) {
                        arrayFranquia.push({
                            id_franquia: item.id_franquia,
                            razao_franquia: item.razao_franquia,
                            data_inalg: item.data_inalg,
                        })
                    }
                })
                if (conta >= 1) {
                    alert("Tem " + conta + " Franquia(s) de aniversário hoje.");
                }
                //  console.log(arraynovo)
                setDadosFranquia(arrayFranquia)
                setControlTab(true);
            })
            .catch((err) => {
                setDadosFranquia([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFranqueados() { // para verificar os aniversários
        setControlTab(!controlTab)

        const dataAtual = moment().format('DD-MM-YYYY');
        let diaAtu = dataAtual.substring(0, 2);
        let mesAtu = dataAtual.substring(3, 5);

        let diaAni = ""
        let mesAni = ""
        let conta = 0;
        let arrayFranqueado = []

        api.get("/franqueados_aniver_listar")
            .then((resp) => {
                // console.log(resp.data)
                resp.data.map((item) => {
                    var dat = item.data_nasc_franqueado;
                    diaAni = dat.substring(0, 2);
                    mesAni = dat.substring(3, 5);

                    if (diaAni === diaAtu && mesAni === mesAtu) {
                        conta = conta + 1;
                        arrayFranqueado.push({
                            id_franqueado: item.id_franqueado,
                            nome_franqueado: item.nome_franqueado,
                            data_nasc_franqueado: item.data_nasc_franqueado,
                        })
                    } else if (mesAni === mesCombobox) {
                        arrayFranqueado.push({
                            id_franqueado: item.id_franqueado,
                            nome_franqueado: item.nome_franqueado,
                            data_nasc_franqueado: item.data_nasc_franqueado,
                        })
                    }
                })
                if (conta >= 1) {
                    alert("Tem " + conta + " franqueado(s) de aniversário hoje.");
                }
                setDadosFranqueado(arrayFranqueado)
                setControlTab(true);
            })
            .catch((err) => {
                setDadosFranqueado([]);
                alert("Erro ao carregar dados");
            });
    }
    function ListarFranquiasCidades() { // para verificar os aniversários cidades
        setControlTab(!controlTab)

        const dataAtual = moment().format('DD-MM-YYYY');
        let diaAtu = dataAtual.substring(0, 2);
        let mesAtu = dataAtual.substring(3, 5);

        let diaAni = ""
        let mesAni = ""
        let conta = 0;
        let arrayCidades = []

        api.get("/franquia_aniver_listar_cidades")
            .then((resp) => {
                // console.log(resp.data)
                resp.data.map((item) => {
                    var dat = item.data_aniver_cidade;
                    diaAni = dat.substring(0, 2);
                    mesAni = dat.substring(3, 5);

                    if (diaAni === diaAtu && mesAni === mesAtu) {
                        conta = conta + 1;
                        arrayCidades.push({
                            id_franquia: item.id_franquia,
                            razao_franquia: item.razao_franquia,
                            cidade_franquia: item.cidade_franquia,
                            data_aniver_cidade: item.data_aniver_cidade,
                        })
                    } else if (mesAni === mesCombobox) {
                        arrayCidades.push({
                            id_franquia: item.id_franquia,
                            razao_franquia: item.razao_franquia,
                            cidade_franquia: item.cidade_franquia,
                            data_aniver_cidade: item.data_aniver_cidade,
                        })
                    }
                })
                if (conta >= 1) {
                    alert("Tem " + conta + " Cidade(s) de aniversário hoje.");
                }
                //  console.log(arraynovo)
                setDadosCidades(arrayCidades)
                setControlTab(true);
            })
            .catch((err) => {
                setDadosCidades([]);
                alert("Erro ao carregar dados");
            });
    }

    return <>
        <div className=" d-flex fundo-img-aniversarios">
            <Navbar tela="aniversarios" />
            <div className="container-fluid">
                <div className="row flex-nowrapl margem-top">
                    <div className="col-11 py-3 ms-4">
                        <div className="bg-white p-4 rounded-4 border mt-4 fundo_img_aniver">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h5 className="mb-5">Imprimir aniversariantes...</h5>
                                </div>
                                <div className="col-1 ">
                                    <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 " >Voltar </Link>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-5">
                                <div className="col-2">
                                    <button className="btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#ModalColaboradores">Colaboradores</button>
                                </div>
                                <div className="col-2">
                                    <button className="  btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#ModalFranquias">Franquias</button>
                                </div>
                                <div className="col-2">
                                    <button className=" btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#ModalFranqueados">Franqueados</button>
                                </div>
                                <div className="col-2">
                                    <button className=" btn btn-primary ms-4" type="button" data-bs-toggle="modal" data-bs-target="#ModalCidades">Cidades</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*----------------------------------------------- Modal Colaboradores Inicio -----------------------------------------*/}
            <div className="modal fade" id="ModalColaboradores" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Colaboradores</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 bg-white rounded-4 " >
                                <div className="container-fluid h-100 pt-4 p-4 ">
                                    <div className="row  ">
                                        <ComboMes />
                                        <div className="col-6 mb-2  ">
                                            <button onClick={ListarColaboradores} id="btsalvar" type="button" className="btn btn-sm btn btn-success mt-4 me-2">Pesquisar</button>
                                            {
                                                dadosCol.length >= 1 ? <button onClick={(e) => AniversariosColaboradoresPDF(dadosCol)} id="btincluir" type="button" className="btn btn-sm btn btn-danger mt-4 me-2 ">Imprimir</button> : null
                                            }

                                        </div>
                                    </div>
                                    <h4 className="d-inline">
                                        <h5 className="mt-3">Relação dos Colaboradores</h5>
                                    </h4>
                                    <div className="row  mt-3 tab-funcao_col">
                                        <div className="bg-white p-4 rounded-4 border">
                                            {
                                                controlTab && dadosCol.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Selecione um mês e clique em Pesquisar!</h6>
                                                </div> : null
                                            }

                                            {
                                                !controlTab && dadosCol.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Nenhum registro encontrado!</h6>
                                                </div> : null
                                            }
                                            <DataTable className="tabela-modal" columns={columnsCol}
                                                data={dadosCol}
                                                pagination={false}
                                                noDataComponent={<p className=""></p>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*----------------------------------------------- Modal Colaboradores Fim -----------------------------------------*/}


            {/*----------------------------------------------- Modal Franquias Inicio -----------------------------------------*/}
            <div className="modal fade" id="ModalFranquias" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Franquias</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 bg-white  rounded-4 " >
                                <div className="container-fluid h-100 pt-4 sidebar p-4 ">
                                    <div className="row  ">
                                        <ComboMes />
                                        <div className="col-6 mb-2  ">
                                            <button onClick={ListarFranquias} id="btsalvar" type="button" className="btn btn-sm btn btn-success mt-4 me-2">Pesquisar</button>
                                            {
                                                dadosFranquia.length >= 1 ? <button onClick={(e) => AniversariosFranquiasPDF(dadosFranquia)} id="btincluir" type="button" className="btn btn-sm btn btn-danger mt-4 me-2">Imprimir</button> : null
                                            }
                                        </div>
                                    </div>
                                    <h4 className="d-inline">
                                        <h5 className="mt-3">Relação das Franquias</h5>
                                    </h4>
                                    <div className="row  mt-3 tab-funcao_col">
                                        <div className="bg-white p-4 rounded-4 border">

                                            {
                                                controlTab && dadosFranquia.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Selecione um mês e clique em Pesquisar!</h6>
                                                </div> : null
                                            }

                                            {
                                                !controlTab && dadosFranquia.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Nenhum registro encontrado!</h6>
                                                </div> : null
                                            }

                                            <DataTable className="tabela-funcao" columns={columnsFran}
                                                data={dadosFranquia}
                                                pagination={false}
                                                noDataComponent={<p className=""></p>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*----------------------------------------------- Modal Franquias Fim -----------------------------------------*/}

            {/*----------------------------------------------- Modal Franqueados Inicio -----------------------------------------*/}
            <div className="modal fade" id="ModalFranqueados" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Franqueados</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 bg-white  rounded-4 " >
                                <div className="container-fluid h-100 pt-4 p-4 ">
                                    <div className="row  ">
                                        <ComboMes />
                                        <div className="col-6 mb-2 ">
                                            <button onClick={ListarFranqueados} id="btsalvar" type="button" className="btn btn-sm btn btn-success mt-4 me-2">Pesquisar</button>
                                            {
                                                dadosFranqueado.length >= 1 ? <button onClick={(e) => AniversariosFranqueadosPDF(dadosFranqueado)} id="btincluir" type="button" className="btn btn-sm btn btn-danger mt-4 me-2">Imprimir</button> : null
                                            }
                                        </div>
                                    </div>
                                    <h4 className="d-inline">
                                        <h5 className="mt-3">Relação Franqueados</h5>
                                    </h4>
                                    <div className="row  mt-3 tab-funcao_col">
                                        <div className="bg-white p-4 rounded-4 border">
                                            {
                                                controlTab && dadosFranqueado.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Selecione um mês e clique em Pesquisar!</h6>
                                                </div> : null
                                            }

                                            {
                                                !controlTab && dadosFranqueado.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Nenhum registro encontrado!</h6>
                                                </div> : null
                                            }

                                            <DataTable className="tabela-funcao" columns={columnsFranqueados}
                                                data={dadosFranqueado}
                                                pagination={false}
                                                noDataComponent={<p className=""></p>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*----------------------------------------------- Modal Franqueados Fim -----------------------------------------*/}

            {/*----------------------------------------------- Modal Cidades Inicio -----------------------------------------*/}
            <div className="modal fade" id="ModalCidades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Cidades</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 bg-white rounded-4 " >
                                <div className="container-fluid h-100 pt-4 sidebar p-4 ">
                                    <div className="row  ">
                                        <ComboMes />
                                        <div className="col-6 mb-2  ">
                                            <button onClick={ListarFranquiasCidades} id="btsalvar" type="button" className="btn btn-sm btn btn-success mt-4 me-2">Pesquisar</button>
                                            {
                                                dadosCidades.length >= 1 ? <button onClick={(e) => AniversariosCidadesPDF(dadosCidades)} id="btincluir" type="button" className="btn btn-sm btn btn-danger mt-4 me-2">Imprimir</button> : null
                                            }
                                        </div>
                                    </div>
                                    <h4 className="d-inline">
                                        <h5 className="mt-3">Relação das Cidades</h5>
                                    </h4>
                                    <div className="row  mt-3 tab-funcao_col">
                                        <div className="bg-white p-4 rounded-4 border">
                                            {
                                                controlTab && dadosCidades.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Selecione um mês e clique em Pesquisar!</h6>
                                                </div> : null
                                            }

                                            {
                                                !controlTab && dadosCidades.length <= 0 ? <div className="d-flex justify-content-center">
                                                    <h6 className="text-secondary">Nenhum registro encontrado!</h6>
                                                </div> : null
                                            }
                                            <DataTable className="tabela-funcao" columns={columnsCidades}
                                                data={dadosCidades}
                                                pagination={false}
                                                noDataComponent={<p className=""></p>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
                {/*----------------------------------------------- Modal Cidades Fim -----------------------------------------*/}
            </div>
        </div>
    </>
}
export default Aniversarios;