import { Dock } from "react-dock";
import { useEffect, useState } from "react";
import api from "../../services/api.js";
import "./colaboradores-cad.css";

import generatePDF, { Margin } from 'react-to-pdf';
import { dadosLogin } from "../../zustand/zuztand.js"
import autorizacaoColaborador from "../../report/report.colaboradores.autorizacao/report.colaboradores.autorizacao.jsx";

function ColaboradoresCad(props) {
    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [id_colaborador, setId_colaborador] = useState(0);
    const [nome_colaborador, setNome_colaborador] = useState("");
    const [data_nasc_col, setData_nasc_col] = useState("");
    const [data_cad_colaborador, setData_cad_colaborador] = useState("");
    const [cpf_colaborador, setCpf_colaborador] = useState("");
    const [cel_colaborador, setCel_colaborador] = useState("");
    const [rg_colaborador, setRg_colaborador] = useState("");
    const [sexo_colaborador, setSexo_colaborador] = useState("");
    const [end_colaborador, setEnd_colaborador] = useState("");
    const [cep_colaborador, setCep_colaborador] = useState("");
    const [bairro_colaborador, setBairro_colaborador] = useState("");
    const [cidade_colaborador, setCidade_colaborador] = useState("");
    const [comp_colaborador, setComp_colaborador] = useState("");
    const [uf_colaborador, setUf_colaborador] = useState("");
    const [obs_colaborador, setObs_colaborador] = useState("");
    const [base64Image, setBase64Image] = useState("");
    const [foto_colaborador, setFoto_colaborador] = useState("");
    const [num_colaborador, setNum_colaborador] = useState("");
    const [data_adm, setData_adm] = useState("");
    const [data_saida_colaborador, setData_saida_colaborador] = useState("");
    const [motivo_colaborador, setMotivo_colaborador] = useState("");
    const [funcao_colaborador, setFuncao_colaborador] = useState("");
    const [nacionalidade_colaborador, setNacionalidade_colaborador] = useState("brasileiro(a)");
    const [dados_funcao, setDados_funcao] = useState([]);
    const [busca, setBusca] = useState("");
    const [dados_col, setDados_col] = useState([]);
    const [nome_menor, setNome_menor] = useState("");
    const [civil_colaborador, setCivil_colaborador] = useState("");

    const id_temp = localStorage.getItem('id_colaborador'); //para montar o id na tela edit
    const texto2 = ("0000" + id_temp).slice(-4);// incluir zeros esquerda
    const textonovo = `- Cód: ${texto2}`; //para montar o id na tela edit

    const { usuario, nivel_user } = dadosLogin();

    const personalizacao = { //para impressão pdf
        // Baixar/Salvar = save / Abrir no navegador = open
        method: 'open',
        page: {
            // Definir a margem: SMALL ou MEDIUM 
            margin: Margin.SMALL,
            // Formato da página: A4 ou letter
            format: 'A4',
            // Orientação do arquivo: portrait ou landscape
            orientation: 'portrait',
        },
    }

    const recuperarConteudoParaPDF = () => document.getElementById('conteudo');

    function getCep() {
        const cepAlu = cep_colaborador.replace(/\D/g, '')
        if (cep_colaborador.length !== 8) {
            alert("CEP inválido.");
        } else {
            fetch(`https://viacep.com.br/ws/${cep_colaborador}/json/`).then(res => res.json()).then(data => {
                //  console.log(data);
                if (data.erro) {
                    alert("CEP não encontrado.")
                } else {
                    setEnd_colaborador(data.logradouro);
                    // localStorage.setItem('Rua', data.logradouro);
                    setBairro_colaborador(data.bairro);
                    // localStorage.setItem('Bairro', data.bairro);
                    setCidade_colaborador(data.localidade);
                    //localStorage.setItem('Local', data.localidade);
                    // setCep(data.cep);
                    setUf_colaborador(data.uf);                }
            });
        }
    }

    function ListarFuncao() {
        api.get("/funcao")
            .then((resp) => {
                setDados_funcao(resp.data);
            })
            .catch((err) => {
                setDados_funcao([]);
                alert("Erro ao pesquisar dados");
            });
    }

    function ChangeDataNascColaborador(e) {
        let ano = e.target.value.substring(0, 4);
        let mes = e.target.value.substring(5, 7);
        let dia = e.target.value.substring(8, 11);
        setData_nasc_col(dia + '/' + mes + '/' + ano);
    }

    function Limpar() {
        setNome_colaborador("");
        setEnd_colaborador("");
        setData_nasc_col("");
        setData_adm("");
        setCpf_colaborador("");
        setRg_colaborador("");
        setData_cad_colaborador("");
        setRg_colaborador("");
        setSexo_colaborador("");
        setCep_colaborador("");
        setBairro_colaborador("");
        setCidade_colaborador("");
        setComp_colaborador("");
        setUf_colaborador("");
        setObs_colaborador("");
        setFoto_colaborador("");
        setNum_colaborador("");
        setCel_colaborador("");
        setBase64Image(localStorage.getItem('foto_padrao'));
        setData_saida_colaborador("");
        setMotivo_colaborador("");
        setFuncao_colaborador("");
        setCivil_colaborador("");
    }

    function SalvarDadosColaborador() {

        // setStatus_edit(0);
        if (nome_colaborador === "" || cpf_colaborador === "") {
            alert("Campo nome e cpf são obrigatórios")
        } else {
            let fotocolaborador = localStorage.getItem('novo_franqueado');
            // setBase64Image(localStorage.getItem('foto_padrao'))
            let dataAtual = new Date();
            var ver_mes = new Date(dataAtual);
            var month = localStorage.setItem('mesCadCol', ver_mes.getMonth());//pega o mes atual não é somado 1 por causa da logica controle mensalidade

            let anoAtualiz = localStorage.setItem('anoAtualiz', dataAtual.getFullYear()); // pega ano atual
            let status_colaborador = "A"
            const params = {

                id_usuario: localStorage.getItem("id_usuario"),
                nome_colaborador,
                cpf_colaborador,
                funcao_colaborador,
                cel_colaborador,
                rg_colaborador,
                data_adm,
                data_nasc_col,
                end_colaborador,
                uf_colaborador,
                bairro_colaborador,
                comp_colaborador,
                cidade_colaborador,
                cep_colaborador,
                obs_colaborador,
                status_colaborador,
                num_colaborador,
                foto_colaborador: fotocolaborador,
                sexo_colaborador,
                data_saida_colaborador,
                motivo_colaborador,
                civil_colaborador
            };

            const params2 = {

                nome_colaborador,
                cel_colaborador,
                data_nasc_col,
                cpf_colaborador,
                funcao_colaborador,
                rg_colaborador,
                data_adm,
                cep_colaborador,
                end_colaborador,
                num_colaborador,
                cidade_colaborador,
                bairro_colaborador,
                comp_colaborador,
                uf_colaborador,
                obs_colaborador,
                sexo_colaborador,
                data_saida_colaborador,
                motivo_colaborador,
                civil_colaborador
            };
            if (operacao === "new") {

                // NOVO REGISTRO........................
                api.post("/colaboradores_insert", params)
                    .then((resp) => {

                        if (resp.status === 201) {
                            setShow(false);
                            props.onClose(); //para atualizar a 
                        } else {
                            alert("Erro ao inserir dados1111111111");
                        }
                    })
                    .catch((err) => {
                        // setDados([]);
                        alert("Erro ao inserir dados");
                    });

            } else {
                // setStatus_edit(1)

                if (nome_colaborador === "" || data_nasc_col === "" || cpf_colaborador === "") {
                    alert("Campo nome, data de nascimento, e cpf são obrigatórios")
                } else {

                    // EDITAR REGISTRO EXISTENTE........................
                    let id = parseInt(localStorage.getItem("id_colaborador"))

                    api.put("/colaboradores_edit/" + id, params2)
                        .then((resp) => {
                            if (resp.status === 200) {
                                alert("Registro editado com sucesso!")
                                setShow(false);
                                props.onClose();
                            } else {
                                alert("Erro ao editar dados");
                            }
                        })
                        .catch((err) => {
                            alert("Erro ao editar dados");
                        });
                }
            }
        }

    }

    useEffect(() => {
        ListarFuncao();

        window.addEventListener('openColaboradores', (param) => {

            if (param.detail.operacao === "new") {
                Limpar();
            } else {
                Limpar();
                localStorage.setItem('id_colaborador_detail', param.detail.id_colab_fran)
                api.get("/colaboradores_id/" + param.detail.id_colab_fran)
                    .then((resp) => {
                        setDados_col(resp.data)
                        setId_colaborador(resp.data.id_colab_fran);
                        setNome_colaborador(resp.data.nome_colaborador);
                        setEnd_colaborador(resp.data.end_colaborador);
                        setCpf_colaborador(resp.data.cpf_colaborador)
                        setRg_colaborador(resp.data.rg_colaborador);
                        setData_nasc_col(resp.data.data_nasc_col);
                        setData_adm(resp.data.data_adm);
                        setSexo_colaborador(resp.data.sexo_colaborador);
                        setCep_colaborador(resp.data.cep_colaborador);
                        setBairro_colaborador(resp.data.bairro_colaborador);
                        setCidade_colaborador(resp.data.cidade_colaborador);
                        setComp_colaborador(resp.data.comp_colaborador);
                        setUf_colaborador(resp.data.uf_colaborador);
                        setNum_colaborador(resp.data.num_colaborador);
                        setObs_colaborador(resp.data.obs_colaborador);
                        setData_saida_colaborador(resp.data.data_saida_colaborador);
                        setMotivo_colaborador(resp.data.motivo_colaborador);
                        setFuncao_colaborador(resp.data.funcao_colaborador);
                        setCel_colaborador(resp.data.cel_colaborador);
                        setCivil_colaborador(resp.data.civil_colaborador);

                        let dataCad = (resp.data.data_cad); //para converter a data no padrao br
                        let ano = dataCad.substring(0, 4);
                        let mes = dataCad.substring(5, 7);
                        let dia = dataCad.substring(8, 10);
                        setData_cad_colaborador(dia + '/' + mes + '/' + ano);//montando a data no padrao br
                        setBase64Image(resp.data.foto_colaborador);
                    })
                    .catch((err) => {
                        alert("Erro ao carregar dados");
                        console.log(err);
                    });
            }
            setShow(true);
            setOperacao(param.detail.operacao);
        });
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setBase64Image(e.target.result);
                //console.log(base64Image)                   //imagem convertida em base64
            };
            reader.readAsDataURL(file);
        }
    };

    function PesquisarFotoColaborador() {

        let id_colaborador = localStorage.getItem('id_colaborador');
        setBase64Image("");

        api.get("/colaboradores_foto/" + id_colaborador)
            .then((resp) => {

                setBase64Image(resp.data.foto_colaborador);
                //console.log(resp.data.foto_colaborador)      

            })
            .catch((err) => {
                setBase64Image([]);
                alert("Erro ao carregar colaborador");
            });
    }


    function SalvarDados() {

        let id_colaborador = localStorage.getItem("id_colaborador")

        if (id_colaborador === "") {
            alert("Erro ao selecionarcolaborador")
        } else {

            const params2 = {
                foto_colaborador: base64Image,


            };
            //console.log(base64Image)
            //console.log(id_colaborador)


            api.put("/colaboradores_edit_foto/" + id_colaborador, params2)
                .then((resp) => {
                    if (resp.status === 200) {
                        alert("Sucesso");

                    } else {
                        alert("Erro ao editar dados");
                    }
                })
                .catch((err) => {

                    alert("Erro ao editar dados");
                });
        }
    }

    return <Dock position="right"
        isVisible={show}
        fluid={false}
        size={1220}
        onVisibleChange={(visible) => {
            setShow(visible);
        }}
    >
        <div className="container-fluid fundo-dock-col p-3" id="conteudo">
            <h5 className="d-inline "> {operacao === "edit" ? "Editar Colaborador " + textonovo : "Novo Colaborador"} </h5>

            <div className="row d-flex mt-2 ">
                <div className="col-3">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"><img className='imagem_foto-colaborador border border-primary' src={base64Image} alt="Imagem Selecionada" /></button>
                </div>
                <div className="col-4 ">
                    <small className="text-secondary">Cpf</small>
                    <input type="text" className="form-control cpf" onChange={(e) => setCpf_colaborador(e.target.value)} value={cpf_colaborador} />
                    <small className="text-secondary">Rg</small>
                    <input type="text" className="form-control cpf" onChange={(e) => setRg_colaborador(e.target.value)} value={rg_colaborador} />
                    <small className="text-secondary">Data Cadastro</small>
                    <div className="input-group mb-3 blocoIcones_aluno2 ">
                        <input type="text" className="form-control dat-cad-fra " id="birth" value={data_cad_colaborador} readonly />
                    </div>
                </div>
                <div className="col-4 ">
                    <small className="text-secondary">Data Nascimento</small>
                    <div className="input-group mb-3 blocoIcones_fran ">
                        <input type="text" className="form-control " id="birth" value={data_nasc_col} />
                        <input type="date" className="form-control dt_nac-btn" onChange={ChangeDataNascColaborador} />

                    </div>


                    <div className="d-flex">
                        <div>
                            <small className="text-secondary">Sexo</small>
                            <select type="text" className="form-control sexo-col " onChange={(e) => setSexo_colaborador(e.target.value)} value={sexo_colaborador} >
                                <option>Selecione</option>
                                <option>Masculino</option>
                                <option>Feminino</option>
                                <option>Outro</option>
                            </select>
                        </div>

                        <div className="ms-5">
                            <small className="text-secondary">Estado Civil</small>
                            <input type="text" className="form-control cpf" onChange={(e) => setCivil_colaborador(e.target.value)} value={civil_colaborador} />

                        </div>

                    </div>

                    <small className="text-secondary blocoIcones">Tel.</small>
                    <input type="text" className="form-control sexo-colaborador" onChange={(e) => setCel_colaborador(e.target.value)} value={cel_colaborador} />
                </div>
            </div>
            <div className="row d-flex mt-2">
                <div className="col-8 ">
                    <small className="text-secondary">Nome Colaborador</small>
                    <input type="text" className="form-control " onChange={(e) => setNome_colaborador(e.target.value)} value={nome_colaborador} />
                </div>
                <div className="col-2 ">
                </div>
                <div className="col-2 ">
                    <small className="text-secondary blocoIcones">CEP:</small>
                    <div className="col-11-neg blocoIcones ">
                        <input type="text" className="form-control input-cep-fran" onChange={(e) => setCep_colaborador(e.target.value)} value={cep_colaborador} />
                        <button className="btn-cep-fran" onClick={getCep}><i className=" bi bi-search btn-cep-fran"></i></button>
                    </div>
                </div>
            </div>
            <div className="row d-flex ">
                <div className="col-8 ">
                    <small className="text-secondary">Rua/Av.</small>
                    <input type="text" className="form-control" onChange={(e) => setEnd_colaborador(e.target.value)} value={end_colaborador} />
                </div>
                <div className="col-2 ">
                    <small className="text-secondary">Número</small>
                    <input type="text" className="form-control numero-cr" onChange={(e) => setNum_colaborador(e.target.value)} value={num_colaborador} />
                </div>
                <div className="col-2 ">
                    <small className="text-secondary">UF</small>
                    <select type="text" className="form-control numero-cr" onChange={(e) => setUf_colaborador(e.target.value)} value={uf_colaborador} >
                        <option value="">Selecione</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                    </select>
                </div>
            </div>
            <div className="row d-flex ">
                <div className="col-6 ">
                    <small className="text-secondary">Bairro</small>
                    <input type="text" className="form-control cidade-cr" onChange={(e) => setBairro_colaborador(e.target.value)} value={bairro_colaborador} />
                </div>
                <div className="col-6 ">
                    <small className="text-secondary">Cidade</small>
                    <input type="text" className="form-control cidade-cr" onChange={(e) => setCidade_colaborador(e.target.value)} value={cidade_colaborador} />
                </div>
            </div>
            <div className="row d-flex ">
                <div className="col-6 ">
                    <small className="text-secondary">Complemento</small>
                    <input type="text" className="form-control " onChange={(e) => setComp_colaborador(e.target.value)} value={comp_colaborador} />
                </div>
                <div className="col-6 ">
                    <small className="text-secondary">Obs:</small>
                    <input type="text" className="form-control " onChange={(e) => setObs_colaborador(e.target.value)} value={obs_colaborador} />
                </div>
            </div>
            <div className="row d-flex ">
                <div className="col-2">
                    <small className="text-secondary">Data Adm.</small>
                    <input type="text" className="form-control " onChange={(e) => setData_adm(e.target.value)} value={data_adm} />
                </div>
                <div className="col-3 ">
                    <small className="text-secondary">Função:</small>
                    <div className="form-control ">
                        <select onChange={(e) => setFuncao_colaborador(e.target.value)} value={funcao_colaborador}>
                            <option>Selecione</option>
                            {
                                dados_funcao.map((item) => {
                                    return <option key={item.id_funcao_col} value={item.desc_funcao}>{item.desc_funcao}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-2">
                    <small className="text-secondary">Data Saída</small>
                    <input type="text" className="form-control " onChange={(e) => setData_saida_colaborador(e.target.value)} value={data_saida_colaborador} />
                </div>
                <div className="col-10">
                    <small className="text-secondary">Motivo</small>
                    <input type="text" className="form-control " onChange={(e) => setMotivo_colaborador(e.target.value)} value={motivo_colaborador} />
                </div>
            </div>
            <div className="d-flex justify-content-end mt-3 me-5">
                <button onClick={() => setNome_menor("")} className="btn btn-primary me-3" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalAutorizacao">Autorização Imagem</button>
                <button onClick={() => generatePDF(recuperarConteudoParaPDF, personalizacao)} className="btn btn-danger me-3"><i className="bi bi-file-earmark-pdf "></i>Imprimir</button>
                <button className="btn btn-outline-primary btn-franqueados" onClick={() => setShow(false)}>Cancelar</button>
                {
                    usuario === "ariscoz@gmail.com" ?
                        <button className="btn btn-primary ms-3" onClick={SalvarDadosColaborador}>Salvar Dados</button> : null
                }
            </div>
            <div>
            </div>
        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Foto</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div >
                            <input type="file" accept="image/*" onChange={handleImageUpload} />

                            {base64Image && (<div><img className='img-col-seleciona' src={base64Image} alt="Imagem Selecionada" /></div>)}
                            <small className="text-secondary">Id :</small>
                            <small className="text-secondary">{id_colaborador}</small>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        {
                            nivel_user === "admin" ?
                                <button onClick={SalvarDados} type="button" className="btn btn-primary">Savar Foto</button> : null
                        }
                    </div>
                </div>
            </div>
        </div>

        {/*----------------------------------------------- Modal Autorização Inicio -----------------------------------------*/}
        <div className="modal fade" id="exampleModalAutorizacao" tabindex="-1" aria-labelledby="exampleModalAutorizacao" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalAutorizacao">Imprimir Autorização de Uso Imagem</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 bg-white  rounded-4 " >
                            <div className="container-fluid h-100  sidebar">
                                <div className="row  ">
                                    <div className="col-6   ">
                                        <label htmlFor="InputNomeCol" className="text-secondary">Colaborador:</label>
                                        <input type="text" className="form-control " id="InputNome" value={nome_colaborador} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InpuCpf" className="text-secondary">CPF</label>
                                        <input type="text" className="form-control " id="InputEnd" value={cpf_colaborador} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="InputRg" className="text-secondary">RG</label>
                                        <input type="text" className="form-control " id="InputEnd" value={rg_colaborador} />
                                    </div>
                                    <div className="col-2   ">
                                        <label htmlFor="Inputcivil" className="text-secondary">Estado Civil</label>
                                        <input type="text" className="form-control " id="civil" value={civil_colaborador} />
                                    </div>
                                    <div className="col-6   ">
                                        <label htmlFor="InputEnd" className="text-secondary">Endereço</label>
                                        <input type="text" className="form-control " id="InputEnd" value={end_colaborador + ', ' + num_colaborador} />
                                    </div>
                                    <div className="col-6   ">
                                        <label htmlFor="InputBairro" className="text-secondary">Bairro</label>
                                        <input type="text" className="form-control " id="InputEnd" value={bairro_colaborador} />
                                    </div>
                                    <div className="col-3   ">
                                        <label htmlFor="InputTel" className="text-secondary">Telefone</label>
                                        <input type="text" className="form-control " id="InputTel" value={cel_colaborador} />
                                    </div>
                                    <div className="col-3   ">
                                        <label htmlFor="InputNac" className="text-secondary">Nacionalidade</label>
                                        <input type="text" className="form-control " id="InputEnd" onChange={(e) => setNacionalidade_colaborador(e.target.value)} value={nacionalidade_colaborador} /><br></br>
                                    </div>

                                    <hr></hr>
                                    <strong>Se a autorização for para uso de imagem e voz de um(a) menor, basta incluir o nome.</strong><br></br>
                                    <br></br>
                                    <div className="col-6   ">
                                        <label htmlFor="Inputmenor" className="text-secondary">Nome menor</label>
                                        <input type="text" className="form-control " id="InputMenor" onChange={(e) => setNome_menor(e.target.value)} value={nome_menor} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button onClick={(e) => autorizacaoColaborador({ ...dados_col, nacionalidade_colaborador, nome_menor, civil_colaborador })} type="button" className="btn btn-primary" disabled={!civil_colaborador || !rg_colaborador || !end_colaborador || !cel_colaborador || !bairro_colaborador || !nacionalidade_colaborador || !cpf_colaborador} >Imprimir</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal Autorização Fim -----------------------------------------*/}

    </Dock>
}

export default ColaboradoresCad;