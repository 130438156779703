import {create} from 'zustand';

export const dadosLogin = create((set) => ({
    ifLogado: "notLogad",
    nivel_user: "usuarioPadrao", 
  
    changeIfLogado: (email) => set((state)=> ({        
        ifLogado: email,
    })),
       changeUser: (email) => set((state)=> ({            //para o usuario aparecer na navbar
        usuario: email,        
    })),
    changeNivel: (nivel_user) => set((state)=> ({            //para o usuario aparecer na navbar
        nivel_user: nivel_user,  
    })),   
    changeNivel: (nivel_user) => set((state)=> ({            //para o usuario aparecer na navbar
        nivel_user: nivel_user,  
    })),         
       
   
}))





