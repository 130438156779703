import { useEffect, useState } from "react";
import "../pagina-modelo/pagina-modelo.css";



function PaginaModelo() {
   

   
    return <>

        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="container-body-fran-cont">

                <div className="d-flex justify-content-center align-items-center">
                    <div className="row ">


                    

                    </div>
                </div>
            </div>
        </div>







         
      
    </>
}
export default PaginaModelo;