import api from "../../services/api.js";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

function FornecedoresContratosStorage() {

    const [dados, setDados] = useState([]);
    const [url_doc_fornecedor, setUrl_doc_fornecedor] = useState("");
    const [id_fornecedor, setId_fornecedor] = useState(0);
    const [nome_fornecedor, setNome_fornecedor] = useState("");


    function PesquisarImgContrato() {//pesquisa ao selecionar a fornecedor no combobox antes do autocomplete

        let id_fornecedor = localStorage.getItem('id_fornecedor');
    
        api.get("/fornecedores_contrato_url", { params: { id_fornecedor } })
          .then((resp) => {
            setDados(resp.data);
            //console.log(resp.data)
          })
          .catch((err) => {
            setDados([]);
            alert("Erro ao carregar dados");
          });
      }

    function ChangeUrl(e) {
      setUrl_doc_fornecedor(e.target.value);
    }

    useEffect(() => {
        setId_fornecedor(localStorage.getItem('id_fornecedor'));
        setNome_fornecedor(localStorage.getItem('nome_fornecedor'));
        PesquisarImgContrato();
    }, []);

    return <>
        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="d-inline justify-content-center  container-body-fran-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className=" col-6 ms-4">
                        <input type="text" className="form-control" value={id_fornecedor + " - " + nome_fornecedor} />
                    </div>

                    <div className="col-2  mb-3">
                        <select className="form-control select-url " name="url" id="url" onChange={ChangeUrl}>
                            <option value="">Selecione o contrato</option>
                            {
                                dados.map((item) => {
                                    return<option key={item.id_contrato_fornecedor} value={item.url_contrato_forn}>{item.descr_contrato}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="col-1 ms-3">
                        <Link to="/fornecedores" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>

                </div>

                <div className="d-flex justify-content-center  overflow-auto">
                    <div className="  d-block">
                        <div className=" col-11">
                            <div className="">
                                <iframe className=" col-11 frame-contratos-franquias ms-4 " src={url_doc_fornecedor}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}
export default FornecedoresContratosStorage;