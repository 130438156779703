import { useEffect, useState } from "react";
import api from "../../services/api.js";
import btnClear from "../../assets/clear.png";
import "../autocomplete-fornecedores/autocomplete-fornecedores.css"

function AutocompleteFornecedoresContratos(props) {

    const [texto, setTexto] = useState("");
    const [dadosFornecedores, setDadosFornecedores] = useState([]); //todas as franquias geral

    function ListarFornecedoresGeral(buscaFornecedor) {//somente id e nome para montar combo pesquisa ao digitar

        if (buscaFornecedor.length > 2) {
            api.get("/fornecedores_busca_contrato/", { params: { buscaFornecedor } }) //select com join db_doc_fornecedores
                .then((resp) => {

                    let novoArray = []//  par filtrar os fornecedores repetidos
                    novoArray = resp.data.filter(function (a) {
                      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
                    }, Object.create(null))
                    setDadosFornecedores(novoArray);                                      
                })
                .catch((err) => {
                    setDadosFornecedores([]);
                    alert("Erro ao carregar dados");
                });
        }
    }

    function Filtrar(e) {
        setTexto(e.target.value);
        ListarFornecedoresGeral(e.target.value);
    }

    function SelecionarFornecedor(id, nome_fornecedor) {
       
        setTexto(nome_fornecedor); //para preencher o input de busca
        setDadosFornecedores([]); //fecha caixa relaçao forn 
        props.onClickId(id); // para enviar o id para a tela fornecedores
        props.onClickNome(nome_fornecedor);
    }

    function Clear() {
        setTexto("");
        props.onClickId(0);
        props.onClickNome("");
        props.onClickUrl(""); //para limpar url do frame
        setDadosFornecedores([]);
    }

    useEffect(() => {
        document.addEventListener('click', (e) => setDadosFornecedores([])); // quando clicar fora da tela de resultado fecha a mesma zerando o array
    }, []);

    return <div className="autocomplete-forn col-12">
        <input type="text" className="form-control" onChange={Filtrar}
            placeholder={props.placeholder} value={texto} />
        {
            texto.length > 0 ?
                <button className="autocomplete-forn-clear" onClick={Clear}>
                    <img src={btnClear} className="autocomplete-forn-img" alt="..."/>
                </button>
                : null
        }

        {
            dadosFornecedores.length > 0 ?
                <div className="autocomplete-forn-items">
                    {
                        dadosFornecedores.map((item) => {
                            return <div key={item.id_fornecedor}
                                className="autocomplete-forn-item"
                                onClick={(e) => SelecionarFornecedor(item.id_fornecedor, item.nome_fornecedor)}
                            >
                                <b>{item.nome_fornecedor}</b><br />
                                <small className="text-secondary">Código: {item.id_fornecedor}</small><br />
                            </div>
                        })
                    }
                </div> : null
        }
    </div>
}
export default AutocompleteFornecedoresContratos;