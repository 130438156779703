import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../franquias-projetos-geral/franquias-projetos-geral.css"
import { Link } from "react-router-dom";

import AutocompleteFranquiaProj from "../../components/autocomplete-franquia-proj/autocomplete-franquia-proj.jsx";


function FranquiasProjetosGeral() {

  const [dados, setDados] = useState([]);
  const [dadosComboBox, setDadosComboBox] = useState([]);
  const [url_proj_franquia, setUrl_proj_franquia] = useState("");
  const [codigoFranquia, setCodigoFranquia] = useState(0);
  const [id_franquia, setId_franquia] = useState(localStorage.getItem('id_franquia'));
  const [nome_franquia, setNome_franquia] = useState("");
  const [texto, setTexto] = useState("");
  const [escolha, setEscolha] = useState(true);
  const [atualizaDados, setAtualizaDados] = useState(true);

  function PesquisarUrlProj() {  //pesquisa conforme o id da franquia

    api.get("/franquia_proj", { params: { id_franquia } })
      .then((resp) => {
        setDados(resp.data);
        //console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  function PesquisarProjEscolhido() { //pesquisa pelo id escolhido no autocomplete  

    setDados([])
    let id_franquia = codigoFranquia;
    api.get("/franquia_proj", { params: { id_franquia } })
      .then((resp) => {
        setDados(resp.data);
        // console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  function ListarFranquiasProjetos() {//somente id e nome para montar combo

    api.get("/franquias_projetos_listar")
      .then((resp) => {
        //console.log(resp.data)
        setDadosComboBox(resp.data);
      })
      .catch((err) => {
        setDadosComboBox([])
        alert("Erro ao montar combo");
      });
  }

  function ChangeUrl(e) {
    setUrl_proj_franquia(e.target.value);
  }

  function ChangeId_franquia(e) {
    setUrl_proj_franquia('');
    localStorage.setItem('id_franquia_pesq', e.target.value);
    setId_franquia(e.target.value);
    setCodigoFranquia(e.target.value)
    setDados([]);
   // PesquisarUrlProj();
  }

  function escolher() { // para alterar o estado escolha e esconder imput
    setUrl_proj_franquia('');
    setEscolha(!escolha);
    ListarFranquiasProjetos();
    setDados([]);
  }

  function atualizaDadosComboDoc() {//para atualizar os dados do combobox url projetos   
    setAtualizaDados(!atualizaDados);
  }

  useEffect(() => {//atualiza o combo de documentos franquia

    if (codigoFranquia > 0) {   
      PesquisarProjEscolhido();
    }

  }, [atualizaDados]);

  return <>
    <div className="d-flex justify-content-center container-fundo-col-contratos">
      <div className="d-inline justify-content-center container-body-projetos">
        <div className="d-flex col-11 mt-3" >
          <div className="d-flex col-12">       
            <>
              <div className="col-2 ms-3">
                <button className="btn btn-outline-primary ms-4 " onClick={escolher}>Digitar/Selecionar</button>
              </div>

              {
                escolha === true ?
                  <div className="col-4 ">
                    < AutocompleteFranquiaProj placeholder="Digite o nome do franquia...."
                      onClickId={setCodigoFranquia} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                      onClickNome={setNome_franquia} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                      onClickTexto={setTexto} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                      onClickUrl={setUrl_proj_franquia}
                      value={nome_franquia}
                  
                    />
                  </div>
                  : null
              }

              {
                escolha === false ?
                  <div className="col-4 ">
                    <select className="form-control " name="url" id="url" onChange={ChangeId_franquia}>
                      <option value="">Selecione a franquia</option>
                      {
                        dadosComboBox.map((item) => {
                          return <option key={item.id_franquia}
                            value={item.id_franquia} >
                            <small className="text-secondary">{item.id_franquia}-</small>
                            <b>{item.nome_franquia}</b>
                            <small className="text-secondary"> - {item.cidade_franquia}</small>
                          </option>
                        })
                      }
                    </select>
                  </div> : null
              }
            </>
            <button onClick={atualizaDadosComboDoc} className="btn btn-sm btn-primary btn-bi-search"><i className="bi-arrow-clockwise"></i> Atualiza</button>

            <div className="col-2 ms-4 mb-3">
              <select disabled={dados.length<=0} className="form-control" name="url" id="url" onChange={ChangeUrl}>
                <option  value="">Sel. o documento</option>
                {
                  dados.map((item) => {
                    return <option key={item.id_proj_franquias} value={item.url_proj_franquia}>{item.id_franquia}-{item.descr_proj}</option>
                  })
                }
              </select>
            </div>
          </div>

          <div className="col-2 ms-3">
            <Link to="/menu-franquias" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
          </div>
        </div>
        <div className="d-flex justify-content-center container-body-projetos overflow-auto">

          <div className="  d-block">
            <div className=" col-11">
              <div>
                <iframe className=" col-11 frame-projetos ms-4" src={url_proj_franquia}></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
}
export default FranquiasProjetosGeral;