import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import FranquiasCad from "../franquias-cad/franquias-cad.jsx";
import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../franquias/franquias.css";
import franquiasPDF from "../../report/report.franquias/report.franquias.jsx";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand.js";

function Franquias() {

    const navigate = useNavigate();
    const [buscaCnpj, setBuscaCnpj] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);

    const [regiao, setRegiao] = useState("");
    const [tipo_franquia, setTipo_franquia] = useState("");
    const [buscaFranquia, setBuscaFranquia] = useState("");
    const [buscaAbrang, setBuscaAbrang] = useState("");

    const [ufPesq, setUfPesq] = useState("");

    const { ifLogado, usuario, nivel_user } = dadosLogin();

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_franquia).slice(-4),  //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Franquia',
            selector: row => row.nome_franquia,
            sortable: true,
            width: "32%",
            compact: true
        },
        {
            name: 'CNPJ',
            selector: row => row.cnpj_franquia,
            sortable: true,
            width: "15%",
            right: true
        },
        {
            name: 'Telefone.',
            selector: row => row.tel_franquia,

            sortable: true,
            width: "0%",
            compact: true
        },
        {
            name: 'Cidade',
            selector: row => row.cidade_franquia,
            width: "30%",
            sortable: true
        },
        {
            name: 'Menu',
            center: true,
            cell: (row) => <>
                <select onClick={() => Pegarid_franquia(row.id_franquia, row.nome_franquia, row.razao_franquia)} className="form-control select-acoes" name="acao" id="acao" onChange={ChangeMenuAcoes} >
                    <option value="Selecione">Selecione</option>
                    <option value="Editar">Editar</option>
                    <option value="Projetos">Projetos</option>
                    <option value="Contratos">Contratos</option>
                    <option value="Senhas">Senhas</option>
                    <option value="Fotos">Fotos</option>
                    {
                        nivel_user === "admin" ?
                            <option className="text-danger fw-bold" value="Excluir">Excluir</option> : null
                    }
                </select>
            </>,
            width: "12%",
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function AltStatusFranquia(id) {
        let id_franquia = id;

        const param2 = {
            status_franquia: "I",
        }
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão da Franquia?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/franquias_edit_status/" + id_franquia, param2)
                            .then((resp) => {
                                if (resp.status === 200) {
                                    localStorage.setItem('id_franquia', '0');//para controle de listagem ao ser removido item
                                    ListarFranquias(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);

                                } else {
                                    alert("Erro ao editar dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao editar dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function NovaFranquia() {
        localStorage.setItem('cep_franquia_temp', '00000000');
        localStorage.setItem('end_franquia_temp', ' ');
        localStorage.setItem('cidade_franquia_temp', ' ');
        localStorage.setItem('bairro_franquia_temp', ' ');
        localStorage.setItem('uf_franquia_temp', ' ');

        const event = new CustomEvent("openFranquias", {
            detail: {
                operacao: "new"
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFranquia(id) {

        localStorage.setItem('id_franquia', id);
        const event = new CustomEvent("openFranquias", {
            detail: {
                operacao: "edit",
                id_franquia: id
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFranquiaSalvo() { //para abrir o ultimo visualizado

        var idf = localStorage.getItem('id_franquia');
        const event = new CustomEvent("openFranquias", {
            detail: {
                operacao: "edit",
                id_franquia: idf
            }
        });
        window.dispatchEvent(event);
    }

    function ListarFranquias(id_usuario, regiao, pagina, qtd_reg_pagina) {

        api.get("/franquias_listar", { params: { id_usuario, regiao, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFranquiasTipo(id_usuario, tipo_franquia, pagina, qtd_reg_pagina) {

        api.get("/franquias_listar_tipo", { params: { id_usuario, tipo_franquia, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFranquiasUf(id_usuario, uf_franquia, pagina, qtd_reg_pagina) {

        api.get("/franquias_listar_uf", { params: { id_usuario, uf_franquia, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
                // console.log(resp.data.dados)
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranquiaNome() {

        api.get("/franquia_busca/", { params: { buscaFranquia } })
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranquiaIdIniciar() {

        let id_franquia = parseInt(localStorage.getItem('id_franquia'))

        api.get("/franquias_id/" + id_franquia)
            .then((resp) => {
                var dados2 = []
                //console.log(resp.data)
                dados2.push({ //montando o array com o objeto recebido
                    'id_franquia': resp.data.id_franquia,
                    'nome_franquia': resp.data.nome_franquia,
                    'razao_franquia': resp.data.razao_franquia,
                    'cnpj_franquia': resp.data.cnpj_franquia,
                    'cidade_franquia': resp.data.cidade_franquia
                })
                setDados(dados2);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranquiaCnpj() {

        api.get("/franquia_busca_cnpj/", { params: { buscaCnpj } })
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranquiaAbrang() {

        api.get("/franquia_busca_abrang/", { params: { buscaAbrang } })
            .then((resp) => {
                setDados(resp.data);
                //  console.log(buscaAbrang)
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFranquiasInativas() {//somente inativas------------------------

        api.get("/franquia_inativas_listar")
            .then((resp) => {
                //console.log(resp.data)
                setDados(resp.data);
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
            });
    }

    function AtualizaTabFranquias(e) {
        ListarFranquias(localStorage.getItem("id_usuario"), "", 1, 100);// btn mostrar todos
    }

    function ChangeRegiao(e) {
        setTipo_franquia("Tipo");
        setRegiao(e.target.value);
        ListarFranquias(localStorage.getItem("id_usuario"), e.target.value, 1, 100);
    }
    function ChangeUf(e) {
        setUfPesq(e.target.value);
        ListarFranquiasUf(localStorage.getItem("id_usuario"), e.target.value, 1, 100);
    }

    function ChangeTipo_franquia(e) {
        setTipo_franquia(e.target.value);
        ListarFranquiasTipo(localStorage.getItem("id_usuario"), e.target.value, 1, 100);
    }

    function ChangeMenuAcoes(e) {
        if (e.target.value === "Editar") {
            EditarFranquia(parseInt(localStorage.getItem('id_franquia')));
        } else if (e.target.value === "Projetos") {
            navigate("/franquias-projetos-storage");
        } else if (e.target.value === "Contratos") {
            navigate("/franquias-contratos-storage");
        } else if (e.target.value === "Senhas") {
            navigate("/franquias-senhas-portal");
        } else if (e.target.value === "Fotos") {
            navigate("/franquias-fotos-view");
        } else if (e.target.value === "Excluir") {
            AltStatusFranquia(parseInt(localStorage.getItem('id_franquia')))
        }
    }

    function ChangePerRows(newQtd, page) {
        ListarFranquias(localStorage.getItem("id_usuario"), regiao, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarFranquias(localStorage.getItem("id_usuario"), regiao, page, 100);
    }

    function AtualizaDados() {//função que é executado com a props onClose da tela franquiasCad
        // ListarFranquias(localStorage.getItem("id_usuario"), regiao, 1, 100);
        PesquisarFranquiaIdIniciar();
    }

    function Pegarid_franquia(id, nome, razao) {
        localStorage.setItem('id_franquia', id);
        localStorage.setItem('nome_franquia', nome);
        localStorage.setItem('razao_franquia', razao);
    }


    useEffect(() => {

        if (buscaFranquia === "") {
            // ListarFranquias(localStorage.getItem("id_usuario"), regiao, 1, 100);
        } else {
            PesquisarFranquiaNome();
        }
    }, [buscaFranquia]);


    useEffect(() => {
        if (buscaCnpj === "") {

        } else {
            PesquisarFranquiaCnpj();
        }
    }, [buscaCnpj]);

    useEffect(() => {
        if (buscaAbrang === "") {

        } else {
            PesquisarFranquiaAbrang();
        }
    }, [buscaAbrang]);

    useEffect(() => {
        localStorage.setItem("id_usuario", 1);


        let id_temp = localStorage.getItem('id_franquia');
        if (id_temp === "0") {
            ListarFranquias(localStorage.getItem("id_usuario"), "", 1, 100);

        } else {
            function esperaAtualizar() {
                PesquisarFranquiaIdIniciar(); // pesquisa o fornecedor pelo id armazenado no storage
            }
            setTimeout(esperaAtualizar, 500); // Executará esperaAtualizar após meio segundo  
        }

    }, []);

    return <>
        <div className="d-flex container-principal">
            {
                ifLogado !== "notLogad" ? <>
                    <FranquiasCad onClose={AtualizaDados} />
                    <Navbar tela="franquias" />

                    <div className="container-fluid margem-top">
                        <div className="row flex-nowrapl">

                            <div className="col py-3 me-3">
                                <div className="input-group justify-content-between">
                                    <div className="col-2 ms-2">

                                    </div>
                                    <div className="col-6">
                                        <div className="input-group justify-content-end">
                                            <input onChange={(e) => setBuscaFranquia(e.target.value)} type="text" className="form-control buscainput-fran" id="pesq" placeholder="Razão social..." value={buscaFranquia} />
                                            <button onClick={AtualizaTabFranquias} className="btn btn-primary mb-2" type="button" id="button-addon2">Mostrar Todas</button>
                                            <button onClick={EditarFranquiaSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" id="button-addon2 "></button>
                                            <button onClick={ListarFranquiasInativas} className="btn btn-primary mb-2 ms-2" type="button" id="button-addon2 ">Inativas</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white p-4 rounded-4  ">
                                    <div className="d-flex justify-content-between  mb-3  titulo-tabela">

                                        <div className="form-control div_regiao">
                                            <select name="reg" id="reg" onChange={ChangeRegiao}>
                                                <option value="">Região</option>
                                                <option value="Norte">Norte</option>
                                                <option value="Sul">Sul</option>
                                                <option value="Sudeste">Sudeste</option>
                                                <option value="Nordeste">Nordeste</option>
                                                <option value="Centro-Oeste">Centro-Oeste</option>

                                            </select>
                                        </div>

                                        <div className="col-1 div_uf ms-3 me-3">
                                            <select type="text" className="form-control fonte-small" onChange={ChangeUf} >
                                                <option value="">UF</option>
                                                <option value="AC">AC</option>
                                                <option value="AL">AL</option>
                                                <option value="AP">AP</option>
                                                <option value="AM">AM</option>
                                                <option value="BA">BA</option>
                                                <option value="CE">CE</option>
                                                <option value="DF">DF</option>
                                                <option value="ES">ES</option>
                                                <option value="GO">GO</option>
                                                <option value="MA">MA</option>
                                                <option value="MS">MS</option>
                                                <option value="MT">MT</option>
                                                <option value="MG">MG</option>
                                                <option value="PA">PA</option>
                                                <option value="PB">PB</option>
                                                <option value="PR">PR</option>
                                                <option value="PE">PE</option>
                                                <option value="PI">PI</option>
                                                <option value="RJ">RJ</option>
                                                <option value="RN">RN</option>
                                                <option value="RS">RS</option>
                                                <option value="RO">RO</option>
                                                <option value="RR">RR</option>
                                                <option value="SC">SC</option>
                                                <option value="SP">SP</option>
                                                <option value="SE">SE</option>
                                                <option value="TO">TO</option>
                                            </select>
                                        </div>

                                        <div className="form-control div_tipo ">
                                            <select name="reg" id="reg" onChange={ChangeTipo_franquia} >
                                                <option value="">Mod.</option>
                                                <option value="Premium">Premium</option>
                                                <option value="Compacta">Compacta</option>
                                                <option value="Quiosque">Quiosque</option>
                                                <option value="Estante Ludica">Estante Lúdica</option>
                                            </select>
                                        </div>

                                        <div className="d-flex" >
                                            <input type="text" className="form-control fonte-small" onChange={(e) => setBuscaAbrang(e.target.value)} placeholder="Cidades abrangência..." />
                                        </div>

                                        <div className="d-flex" >
                                            <small className="text-secundary  mt-2 me-1 ">CNPJ</small>
                                            <input type="text" className="form-control cnpj" onChange={(e) => setBuscaCnpj(e.target.value)} placeholder="__.___.___/____-__" />
                                        </div>

                                        <div className="align-items-end">
                                            <button onClick={(e) => franquiasPDF(dados)} className="btn btn-danger me-1 "><i className="bi bi-printer p-2"></i>Imprimir</button>
                                            {
                                                usuario === "ariscoz@gmail.com" ?
                                                    <button onClick={NovaFranquia} className="btn btn-primary btn-fran-novo  me-1 ms-1">+ Franquia</button> : null
                                            }
                                        </div>
                                    </div>

                                    <DataTable className="tabela-franquias" columns={columns}
                                        data={dados}
                                        pagination={false}
                                        noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}

                                    />
                                    <div className="d-flex mt-2 justify-content-end me-5">
                                        <small>Total de registros: {dados.length}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></> : navigate('/login')
            }
        </div>
    </>
}
export default Franquias;