import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt-br'
import api from "../../services/api.js";
import { useEffect, useState } from "react";

import "../aniversario-cidades/aniversario-cidades.css";

function AniversarioCidades() {
  const local = moment().locale('pt-br').format('LL'); //converte a data par pt-br
  const local_novo = local.slice(5); // separa o texto inicial ex 12 se setembro de 2024 ficando somente setembro...
  const [dados, setDados] = useState([]);

  useEffect(() => {
    var mes = new Date();
    var month = mes.getMonth();
    var mesAtual = month + 1;
    let novo_array = [];

    api.get("/franquia_busca")
      .then((resp) => {
        // console.log(resp.data)
        resp.data.map((item) => {
          let dtaAniver = parseInt(item.data_aniver_cidade.substring(3, 5));

          if (dtaAniver === mesAtual) {
            novo_array.push({
              nome: item.razao_franquia,
              cid: item.cidade_franquia,
              img: item.img_cidade,
              data: item.data_aniver_cidade
            })
          }
        })
        // console.log(novo_array)
        setDados(novo_array);
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }, []);

  return <>
    <div className="d-flex container-fundo-cidades">
      <div className="d-block container-body-cidades">
        <div className="d-flex justify-content-between">
          <div className="col-1">
          </div>
          <h4 className="ms-3 mt-2">
            Cidades Aniversariantes: {local_novo}
          </h4>
          <div className="col-1 ">
            <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 btn-sm" >Voltar </Link>
          </div>
        </div>
        {
          dados.length <= 3 ?
            <div className="d-flex flex-row p-2 div-cards-cid-center">
              {
                dados.map((item) => {
                  return <div key={item.id_franquias} className="d-flex">

                    <div className="col-10">
                      <img src={item.img} className=" img-card-cidades ms-3" alt="..." />
                      <div className="ms-3 mt-2 d-flex">
                        <p className="text-dark">{item.cid}</p>
                      </div>
                      <div className="ms-3  d-flex">
                        <p className="text-secondary">{item.data}.</p>
                      </div>
                      <div className="ms-3  d-flex">
                        <p className="text-secondary">{item.nome}.</p>
                      </div>
                    </div>

                  </div>
                })
              }
            </div>
            : <div className="d-flex flex-row p-2 div-cards-cid">
              {
                dados.map((item) => {
                  return <div key={item.id_franquias} className="d-flex">
                    <div className="col-10">
                      {item.img ?
                        <img src={item.img} className=" img-card-cidades ms-3" alt="..." /> : <img src="  https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/franquias%2Fimg_cidades%2Faniver_cidade.jpg?alt=media&token=bbae0f5a-715e-4c2f-9d6d-6ed01d31b4c1" className=" img-card-cidades ms-3" alt="..." />
                      }
                      <div className="ms-3 mt-2 d-flex">
                        <p className="text-dark">{item.cid}</p>
                      </div>
                      <div className="ms-3  d-flex">
                        <p className="text-secondary">{item.data}.</p>
                      </div>
                      <div className="ms-3  d-flex">
                        <p className="text-secondary">{item.nome}.</p>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
        }
      </div>
    </div>
  </>
}
export default AniversarioCidades;