import { useState } from "react";
import { dadosLogin } from "../../zustand/zuztand";
import { Link } from "react-router-dom";
import "../calendario_geral/calendario_geral.css"
import Calendario from "../../components/calendario/calendario.jsx";

function CalendarioGeral(props) {
  const [userLogado, setUserLogado] = useState("");
  const { changeIfLogado, ifLogado } = dadosLogin()
  return <>

    <div className="d-flex container-fundo-geral">
      <div className="d-flex container-body-geral align-items-center">
        <div className="col-10 border calendario-geral">
          <Calendario tipo="geral" className="" />
        </div>
        <div>
          <Link to="/menu-calendario" className="btn btn-success p-2 mt-2 buton-geral-voltar d-flex " >Voltar </Link>
          <button type="button" className="btn btn-primary mt-1 buton-geral-listar d-flex" data-bs-toggle="modal" data-bs-target="#ModalLista">Listar</button>
        </div>
      </div>
    </div>
  </>
}
export default CalendarioGeral;