import { Link,  useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/pt-br'
import api from "../../services/api.js";
import Logo from "../../assets/cl_logo.png"
import "../aniversario-franquias/aniversario-franquias.css";
import { dadosLogin } from "../../zustand/zuztand.js"

function AniversarioFranquias() {

  const navigate = useNavigate();
  const { ifLogado, usuario, nivel_user } = dadosLogin()

  const local = moment().locale('pt-br').format('LL'); //converte a data par pt-br
  const local_novo = local.slice(5); // separa o texto inicial ex 12 se setembro de 2024 ficando somente setembro...
  const [dados, setDados] = useState([]);

  useEffect(() => {

    var mes = new Date();
    var month = mes.getMonth();
    var mesAtual = month + 1;
    let novo_array = [];

    api.get("/franquia_busca")
      .then((resp) => {
        // console.log(resp.data)
        resp.data.map((item) => {
          let dtaAniver = parseInt(item.data_inalg.substring(3, 5));

          if (dtaAniver === mesAtual) {
            novo_array.push({
              nome: item.razao_franquia,
              data: item.data_inalg,
              cid: item.cidade_franquia,
              img: item.img_franquia,
              uf: item.uf_franquia
            })
          }
        })
        // console.log(novo_array)
        setDados(novo_array);
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });

  }, []);

  return <>
    <div className="d-flex container-fundo-franquias">
      <div className="d-block container-body-franquias">
      {
          ifLogado !== "notLogad" ? <>

        <div className="d-flex justify-content-between">
          <div className="col-1">
          </div>
          <h4 className="ms-3 mt-2">
            Aniversariantes: {local_novo}
          </h4>
          <div className="col-1 ">
            <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 btn-sm" >Voltar </Link>
          </div>
        </div>

          {
            dados.length<=3?
            <div className="d-flex flex-row p-2 div-cards-center">
            {
              dados.map((item) => {
                return <div key={item.id_franquia} className="d-flex">
  
                  <div className="col-10">
                    {item.img?
                     <img src={item.img} className="img-card-franquias ms-3" alt="..." />:
                     <img src={Logo} className="img-card-franquias ms-3" alt="..." />
                  
                  }                   
                    <div className="ms-3 mt-2 d-flex">
                      <p className="text-dark">{item.nome}</p>
                    </div>
                    <div className="ms-3  d-flex">
                      <p className="text-secondary">{item.cid} - {item.uf}</p>
                    </div>
                    <div className="ms-3  d-flex">
                      <p className="text-secondary">{item.data}</p>
                    </div>
                  </div>
                </div>
              })
            }
          </div>                    
            
            :<div className="d-flex flex-row p-2 div-cards-start">
            {
              dados.map((item) => {
                return <div key={item.id_franquia} className="d-flex">
  
                  <div className="col-10">
                    {item.img?
                     <img src={item.img} className="img-card-franquias ms-3" alt="..." />:
                     <img src={Logo} className="img-card-franquias ms-3" alt="..." />
                  
                  }                   
                    <div className="col-12 ms-3 mt-2 d-flex">
                      <p className="text-dark">{item.nome}</p>
                    </div>
                    <div className="ms-3 d-flex">
                      <p className="text-secondary">{item.cid} - {item.uf}</p>
                    </div>
                    <div className="ms-3 d-flex">
                      <p className="text-secondary">{item.data}</p>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
          }        
         </> :navigate('/login')
        }   
      </div>
    </div>
  </>
}
export default AniversarioFranquias;